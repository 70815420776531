import { ReactNode } from "react";
import cn from "classnames";
import styles from "./overlay.module.css";

interface OverlayProps {
  children?: ReactNode | ReactNode[];
  className?: string;
}

export const Overlay = ({ children, className }: OverlayProps) => {
  return <div className={cn(styles.overlay, className)}>{children}</div>;
};
