import cn from "classnames";
import styles from "./whatWeDoCard.module.css";

interface WhatWeDoCardProps {
  className?: string;
  iconSrc: string;
  title: string;
  description: string;
}

export const WhatWeDoCard = ({ className, title, description, iconSrc }: WhatWeDoCardProps) => {
  return (
    <div className={cn(styles.card, className)}>
      <div className={styles.iconContainer}>
        <img className={styles.icon} src={iconSrc} alt={`${title} icon`} />
      </div>
      <div className={styles.body}>
        <h4 className={styles.title}>{title}</h4>
        <p className={styles.description}>{description}</p>
      </div>
    </div>
  );
};
