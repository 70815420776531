import React, { useContext, useEffect, useState } from "react";
import { Button, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { api } from "../../../services/httpService";
import { AuthContext } from "../../../providers/authProvider";
import { MultipleValuesInput } from "../../../components/multipleValuesInput";
import { BackButton } from "../../../components/backButton";
import { User } from "../../../types/user";

interface UserSSNInfoInterface {
  financialRelations?: string[];
  previousLiveAddress?: string[];
  recentEmployers?: string[];
}

const initialState = {};

const parseUserSSNInfo = (user: User | null): UserSSNInfoInterface => {
  const result = {
    financialRelations: user?.financialRelations ? user.financialRelations.split("\n") : [],
    previousLiveAddress: user?.previousLiveAddress ? user.previousLiveAddress.split("\n") : [],
    recentEmployers: user?.recentEmployers ? user.recentEmployers.split("\n") : [],
  };

  const totalSSNInfoCount = Object.values(result).reduce((acc, item) => item.length + acc, 0);
  return totalSSNInfoCount === 0 ? initialState : result;
};

export const UserSSNInfo = () => {
  const { update, user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ssnInfo, setSSNInfo] = useState<UserSSNInfoInterface>(parseUserSSNInfo(user));
  const [error, setError] = useState<string>();
  const navigate = useNavigate();

  const checkSSNInfo = () => {
    if (Object.values(ssnInfo).reduce((acc, item) => acc + item.length, 0) === 0) {
      setError("You need to add at least one info.");
      return false;
    } else {
      setError(undefined);
      return true;
    }
  };

  useEffect(() => {
    if (initialState === ssnInfo) {
      return;
    }
    checkSSNInfo();
  }, [ssnInfo]);

  const prepareRequestBody = () => {
    // @ts-ignore
    const keys: Array<keyof UserSSNInfoInterface> = Object.keys(ssnInfo);

    const result: Record<string, string> = {};
    keys.forEach((key) => {
      const options = ssnInfo[key] || [];

      result[key] = options.join("\n");
    });

    return result;
  };
  const onSubmit = async () => {
    const check = checkSSNInfo();
    if (!check) {
      return;
    }
    setIsLoading(true);
    await api.put(`${process.env.REACT_APP_API_URL}/api/user/update-ssn-info`, { ...prepareRequestBody() });
    setIsLoading(false);
    update();
    navigate("/", { replace: true });
  };

  const onOptions = (name: keyof UserSSNInfoInterface) => {
    return (options: string[]) => {
      setSSNInfo((prev) => {
        const cloned = { ...prev };

        cloned[name] = options;

        return cloned;
      });
    };
  };

  return (
    <>
      <BackButton />
      <div className="formContainer">
        <h3 className="formTitle">SSN Info</h3>
        <div className="formInputs">
          <MultipleValuesInput
            title="What banks did the user have financial relations with?"
            onOptions={onOptions("financialRelations")}
            disabled={isLoading}
            defaultOptions={ssnInfo.financialRelations}
          />
          <MultipleValuesInput
            title="At which addresses did the user live before?"
            onOptions={onOptions("previousLiveAddress")}
            disabled={isLoading}
            defaultOptions={ssnInfo.previousLiveAddress}
          />
          <MultipleValuesInput
            title="Who were the users' most recent employers?"
            onOptions={onOptions("recentEmployers")}
            disabled={isLoading}
            defaultOptions={ssnInfo.recentEmployers}
          />

          {error && <Typography.Text type="danger">{error}</Typography.Text>}
          <div className="formButton">
            <Button loading={isLoading} onClick={onSubmit}>
              Finish
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
