import React, { useEffect, useState } from "react";
import { Drawer, Dropdown } from "antd";
import DownOutlined from "@ant-design/icons/DownOutlined";
import { Link } from "react-router-dom";
import styles from "../layout.module.css";

interface MobileMenuDrawerProps {
  onClose?: () => void;
  open?: boolean;
}

export const MobileMenuDrawer = ({ onClose: propsOnClose, open: propsOpen }: MobileMenuDrawerProps) => {
  const [open, setOpen] = useState<boolean>(propsOpen !== undefined ? propsOpen : false);

  useEffect(() => {
    setOpen(propsOpen !== undefined ? propsOpen : open);
  }, [propsOpen]);

  const onClose = () => {
    setOpen(false);
    propsOnClose && propsOnClose();
  };

  return (
    <Drawer title={null} placement="left" closable={true} onClose={onClose} open={open}>
      <div>
        <Link onClick={onClose} to="/" className={styles.menuItem}>
          Home
        </Link>
        <Link onClick={onClose} to="/about-us" className={styles.menuItem}>
          About Us
        </Link>
        <Dropdown
          menu={{
            items: [
              {
                label: "Item 1",
                key: "0",
              },
              {
                label: "Item 2",
                key: "1",
              },
            ],
          }}
          trigger={["click"]}
        >
          <a className={styles.menuItem} onClick={(e) => e.preventDefault()}>
            Services
            <DownOutlined className={styles.servicesIcon} />
          </a>
        </Dropdown>
        <Link onClick={onClose} to="/what-we-do" className={styles.menuItem}>
          What we do
        </Link>
        <Link onClick={onClose} to="/contact-us" className={styles.menuItem}>
          Contact Us
        </Link>
      </div>
    </Drawer>
  );
};
