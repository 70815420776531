import React, { useState } from "react";
import { Button, Col, Input, Row } from "antd";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import PlusOutlined from "@ant-design/icons/PlusOutlined";

interface MultipleValuesInputProps {
  disabled: boolean;
  title: string;
  onOptions: (options: string[]) => void;
  defaultOptions?: string[];
}

export const MultipleValuesInput = ({ title, onOptions, disabled, defaultOptions }: MultipleValuesInputProps) => {
  const [options, setOptions] = useState<string[]>(defaultOptions || []);
  const [value, setValue] = useState<string>();

  const removeOption = (index: number) => {
    return () => {
      setOptions((prev) => {
        const cloned = prev.slice();

        cloned.splice(index, 1);
        onOptions(cloned);
        return cloned;
      });
    };
  };

  const handleAddOption = () => {
    if (!value) {
      return;
    }
    const newOptions = [...options, value];
    setOptions(newOptions);
    onOptions(newOptions);

    setValue("");
  };

  return (
    <div>
      <p>{title}</p>
      {options.map((option, index) => (
        <Row key={index} justify="space-between" wrap={false} align="middle">
          <Col>
            <p>{option}</p>
          </Col>
          <Col>
            <Button
              disabled={disabled}
              onClick={removeOption(index)}
              type="primary"
              shape="circle"
              icon={<DeleteOutlined />}
              size="small"
            />
          </Col>
        </Row>
      ))}
      <Row justify="space-between" wrap={false} align="middle">
        <Col>
          <Input
            disabled={disabled}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
        </Col>
        <Col>
          <Button
            disabled={disabled ? disabled : !value || value?.length === 0}
            onClick={handleAddOption}
            type="primary"
            shape="circle"
            icon={<PlusOutlined />}
            size="small"
          />
        </Col>
      </Row>
    </div>
  );
};
