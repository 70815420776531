import React, { useContext, useEffect, useState } from "react";
import { Typography } from "antd";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Input } from "../../components/form/input";
import { PasswordInput } from "../../components/form/input/passwordInput";
import { Button } from "../../components/button";
import { Link } from "../../components/link";
import { api } from "../../services/httpService";
import { ACCESS_TOKEN } from "../../constants/localStorageConstants";
import { AuthContext } from "../../providers/authProvider";
import styles from "./login.module.css";

interface LoginBody {
  email: string;
  password: string;
}

const schema = yup
  .object({
    email: yup
      .string()
      .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, "The email is not valid.")
      .required(),
    password: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Password should be more than 8 characters, with at least 1 uppercase (A-Z) or lowercase letter (a-z), at least 1 numeric character (0-9), and at least 1 special character."
      )
      .required(),
  })
  .required();

export const Login = () => {
  const { update } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [remeberMe, setRememberMe] = useState<boolean>(true);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginBody>({
    resolver: yupResolver(schema),
    defaultValues: { email: "", password: "" },
  });
  const [redirectUrl, setRedirectUrl] = useState<string>("/");
  const [wrongLoginBody, setWrongLoginBody] = useState<boolean>(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const url = searchParams.get("redirect-url");
    if (url && url.length > 0) {
      setRedirectUrl(url);
    }
  }, [searchParams]);
  const onSubmit = async ({ email, password }: LoginBody) => {
    localStorage.removeItem(ACCESS_TOKEN);
    setIsLoading(true);
    try {
      const result = await api.post(`${process.env.REACT_APP_API_URL}/api/auth/local`, {
        identifier: email,
        password,
      });

      setWrongLoginBody(result.status !== 200);
      if (result.status === 200) {
        localStorage.setItem(ACCESS_TOKEN, result.data.jwt);
        await update();
        navigate(redirectUrl);
      }
    } catch {
      setWrongLoginBody(true);
    }

    setIsLoading(false);
  };

  return (
    <div className="formContainer">
      <h3 className="formTitle">Login</h3>
      <div className="formInputs">
        <div>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { ...props } }) => <Input label="Email" type="email" disabled={isLoading} {...props} />}
            name="email"
          />
          {errors.email && <Typography.Text type="danger">{errors.email.message}</Typography.Text>}
        </div>
        <div>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { ...props } }) => <PasswordInput label="Password" {...props} />}
            name="password"
          />
          {errors.password && <Typography.Text type="danger">{errors.password.message}</Typography.Text>}
        </div>
        {wrongLoginBody && <Typography.Text type="danger">Wrong login data</Typography.Text>}
        <Button type="primary" loading={isLoading} onClick={handleSubmit(onSubmit)}>
          {isLoading ? <LoadingOutlined /> : "Continue"}
        </Button>
        <div>
          <Link className={styles.link} to="/forgot-password">
            Forgot Password
          </Link>
          <Link className={styles.link} to="/join-us">
            Join Us
          </Link>
        </div>
      </div>
    </div>
  );
};
