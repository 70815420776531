import React from "react";
import { KnowMore } from "../knowMore";
import { Section } from "../section";

import WhoWeAreBanner from "../../assets/whoWeAreBlock/whoWeAreBanner.png";
import WhoWeAreMobileBanner from "../../assets/whoWeAreBlock/whoWeAreBannerMobile.png";

import TargetImage from "../../assets/targetImage.png";
import { ReactComponent as LeftGameCubeIcon } from "../../assets/gameCubeV2.svg";
import { ReactComponent as RightGameCubeIcon } from "../../assets/gameCubeV4.svg";
import { ReactComponent as WhoWeAreText } from "../../assets/whoWeAreBlock/whoWeAreText.svg";
import { ReactComponent as WhoWeAreTextMobile } from "../../assets/whoWeAreBlock/whoWeAreTextHorizontal.svg";

import styles from "./whoWeAreBlock.module.css";

export const WhoWeAreBlock = () => {
  return (
    <div className={styles.container}>
      <Section className={styles.sectionContainer}>
        <div className={styles.whoWeAreBannerContainer}>
          <LeftGameCubeIcon className={styles.bannerLeftCube} />
          <RightGameCubeIcon className={styles.bannerRightCube} />
          <WhoWeAreText className={styles.whoWeAreText} />
          <WhoWeAreTextMobile className={styles.whoWeAreTextMobile} />
          <img className={styles.whoWeAreBanner} src={WhoWeAreBanner} alt="Who we are banner" />
          <img className={styles.whoWeAreMobileBanner} src={WhoWeAreMobileBanner} alt="Who we are banner mobile" />
          <div className={styles.whoWeAreOverlay} />
        </div>
        <div className={styles.content}>
          <div className={styles.whoWeAreBlock}>
            <KnowMore className={styles.knowMore} />
            <h3 className={styles.whoWeAreTitle}>Who we are</h3>
            <p className={styles.whoWeAreDescription}>
              At Upoptool, we are the leading pioneers in marketing analytics solutions customized exclusively for the
              dynamic world of casinos. With years of industry expertise and a relentless commitment to data-driven
              excellence, we empower casinos to navigate the complex landscape of marketing with precision and
              confidence.
            </p>
          </div>
          <div className={styles.contentItems}>
            <div className={styles.contentItem}>
              <div className={styles.itemImageContainer}>
                <img src={TargetImage} alt="Who we are image" />
              </div>
              <h5 className={styles.contentItemTitle}>Our vision</h5>
              <p className={styles.contentItemDescription}>
                We envision a future where every casino maximizes its potential, delivers exceptional player
                experiences, and achieves sustained success through data-driven marketing. Our dedication to innovation
                and excellence drives us forward, propelling your casino to new heights.
              </p>
            </div>
            <div className={styles.contentItem}>
              <div className={styles.itemImageContainer}>
                <img src={TargetImage} alt="Who we are image" />
              </div>
              <h5 className={styles.contentItemTitle}>Our vision</h5>
              <p className={styles.contentItemDescription}>
                We envision a future where every casino maximizes its potential, delivers exceptional player
                experiences, and achieves sustained success through data-driven marketing. Our dedication to innovation
                and excellence drives us forward, propelling your casino to new heights.
              </p>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
};
