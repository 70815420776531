import React, { ReactNode, useMemo } from "react";
import { Col, Row, Steps } from "antd";
import { Outlet, useLocation } from "react-router-dom";

interface SignUpStepInterface {
  title: ReactNode;
  key: string;
}

const signUpLayoutSteps: SignUpStepInterface[] = [
  { title: <span>Personal info</span>, key: "" },
  { title: <span>Documents</span>, key: "documents" },
  { title: <span>SSN</span>, key: "ssn-info" },
];
export const SignUp = () => {
  const location = useLocation();

  const currentStep = useMemo(() => {
    return signUpLayoutSteps.findIndex((step) => {
      const currentKey = location.pathname.replace("/sign-up", "").replaceAll("/", "");

      return step.key === currentKey;
    });
  }, [location.pathname]);

  return (
    <Row justify="space-between" wrap={false}>
      <Col flex="auto">
        <Outlet />
      </Col>
      <Col>
        <Steps progressDot current={currentStep} direction="vertical" items={signUpLayoutSteps} />
      </Col>
    </Row>
  );
};
