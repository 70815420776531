import React, { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Typography, Result, Tooltip } from "antd";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";
import SmileOutlined from "@ant-design/icons/SmileOutlined";
import { checkEmail, checkPhoneNumber } from "../../helpers/formHelpers";
import { JoinUsBody, saveJoinUsRegisterBody } from "../../helpers/registerHelpers";
import { Overlay } from "../../components/overlay";
import { PageBanner } from "../../components/pageBanner";
import { Section } from "../../components/section";
import { Button } from "../../components/button";
import { Input } from "../../components/form/input";

import { ReactComponent as JoinUsImage } from "../../assets/joinUs/joinUsImage.svg";
import { ReactComponent as CurveIcon } from "../../assets/curve.svg";

import styles from "./joinUs.module.css";

const schema = yup
  .object({
    firstName: yup.string().required("First name required"),
    surName: yup.string().required("Surname required"),
    email: yup
      .string()
      .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, "Wrong email")
      .required(),
    phoneNumber: yup
      .string()
      .matches(/^[0-9]{3}[-\s]?[0-9]{3}[-\s]?[0-9]{4}$/, "Wrong phone number")
      .required(),
  })
  .required();

export const JoinUs = () => {
  // This is just a joke, (you can delete this code if you want) (imageRef, winContainerStyle)
  const imageRef = useRef<SVGSVGElement>(null);
  const [winContainerStyle, setWinContainerStyle] = useState({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<JoinUsBody>({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: "",
      surName: "",
      email: "",
      phoneNumber: "",
    },
  });
  const navigate = useNavigate();

  // This is just a joke, (you can delete this code if you want)
  useEffect(() => {
    const imageBlock = document.querySelector("#win");

    if (!imageBlock) {
      return;
    }

    const handler = () => {
      const { width, height, top, left } = imageBlock.getBoundingClientRect();
      setWinContainerStyle({ width, height: height / 2, top: top + height / 2, left });
    };

    window.addEventListener("resize", handler);
    handler();
    const timeout = setTimeout(() => {
      handler();
    }, 1000);
    return () => {
      clearTimeout(timeout);
      window.removeEventListener("resize", handler);
    };
  }, [imageRef.current]);

  const duplicatesCheck = async (body: JoinUsBody) => {
    const emailCheck = await checkEmail(body.email);
    const phoneCheck = await checkPhoneNumber(body.phoneNumber);
    if (!emailCheck) {
      setError("email", { type: "custom", message: "Current email already registered" });
      return;
    } else {
      clearErrors("email");
    }
    if (!phoneCheck) {
      setError("phoneNumber", { type: "custom", message: "Current phone number already registered" });
      return;
    } else {
      clearErrors("phoneNumber");
    }

    return true;
  };

  const onSubmit = async (body: JoinUsBody) => {
    setIsLoading(true);
    const check = await duplicatesCheck(body);
    if (!check) {
      setIsLoading(false);
      return;
    }

    saveJoinUsRegisterBody(body);
    setIsLoading(false);
    setSuccess(true);
  };

  const openRegistration = () => {
    navigate("/sign-up");
  };

  return (
    <div>
      {success && (
        <Overlay>
          <Result
            className={styles.successResult}
            icon={<SmileOutlined />}
            title="Great, we've saved the essentials!"
            extra={
              <Button type="primary" onClick={openRegistration}>
                Continue your registration
              </Button>
            }
          />
        </Overlay>
      )}
      <PageBanner
        title="Join Us"
        description="Beatae vitae dicta sunt explicabo. Nemo enim ipsam"
        className={styles.banner}
      />
      <div className={styles.container}>
        <Section className={styles.section}>
          <div className={styles.contentBanner}>
            <p className={styles.contentDescription}>Beatae vitae dicta sunt explicabo. Nemo enim ipsam</p>
            <JoinUsImage ref={imageRef} className={styles.contentBannerImage} />
            <Tooltip title="There is a win here!" trigger="hover">
              <div style={{ position: "fixed", zIndex: 3, cursor: "pointer", ...winContainerStyle }} />
            </Tooltip>
          </div>
          <div className={styles.formContainer}>
            <div className={styles.form}>
              <div className={styles.formWelcome}>Welcome</div>
              <CurveIcon className={styles.formCurve} />
              <h4 className={styles.formTitle}>Fill the Join Us Form</h4>

              <div className={styles.formInputs}>
                <div className={styles.usernameBlock}>
                  <div className={styles.firstName}>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { ...props } }) => (
                        <Input
                          className={styles.formInput}
                          error={!!errors.firstName}
                          label="First Name"
                          disabled={isLoading}
                          {...props}
                        />
                      )}
                      name="firstName"
                    />
                    {errors.firstName && <Typography.Text type="danger">{errors.firstName.message}</Typography.Text>}
                  </div>
                  <div className={styles.lastName}>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { ...props } }) => (
                        <Input
                          className={styles.formInput}
                          error={!!errors.surName}
                          label="Surname"
                          disabled={isLoading}
                          {...props}
                        />
                      )}
                      name="surName"
                    />
                    {errors.surName && <Typography.Text type="danger">{errors.surName.message}</Typography.Text>}
                  </div>
                </div>

                <div>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { ...props } }) => (
                      <Input
                        className={styles.formInput}
                        error={!!errors.email}
                        label="Email"
                        type="email"
                        disabled={isLoading}
                        {...props}
                      />
                    )}
                    name="email"
                  />
                  {errors.email && <Typography.Text type="danger">{errors.email.message}</Typography.Text>}
                </div>
                <div>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { ...props } }) => (
                      <Input
                        className={styles.formInput}
                        label="Phone Number"
                        type="phone"
                        disabled={isLoading}
                        error={!!errors.phoneNumber}
                        {...props}
                      />
                    )}
                    name="phoneNumber"
                  />
                  {errors.phoneNumber && <Typography.Text type="danger">{errors.phoneNumber.message}</Typography.Text>}
                </div>
              </div>

              <Button
                disabled={isLoading}
                type="primary"
                className={styles.formSubmit}
                onClick={handleSubmit(onSubmit)}
              >
                {isLoading ? <LoadingOutlined /> : "Submit"}
              </Button>
            </div>
          </div>
        </Section>
      </div>
    </div>
  );
};
