import React, { ForwardedRef, forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Button, Input, Modal, Spin, Typography } from "antd";
import { api } from "../../../services/httpService";
import styles from "./inviteCodeCheckModal.module.css";

export interface InviteModalRefInterface {
  openCheckInviteModal: () => Promise<string | undefined>;
  onClose: (callback: () => void) => void;
}

export interface InviteModalProps {}
export const InviteCodeCheckModal = forwardRef(
  (props: InviteModalProps, ref: ForwardedRef<InviteModalRefInterface>) => {
    const onCloseCallback = useRef<() => void>(() => {});
    const [checkInviteCodeCallback, setCheckInviteCodeCallback] = useState<(res: string | undefined) => void>();
    const [open, setOpen] = useState<boolean>(false);
    const [code, setCode] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();

    useImperativeHandle(
      ref,
      () => {
        return {
          openCheckInviteModal: () => {
            setOpen(true);
            return new Promise<string | undefined>((resolve) => {
              setCheckInviteCodeCallback(() => {
                return resolve;
              });
            });
          },
          onClose: (callback: () => void) => {
            onCloseCallback.current = callback;
          },
        };
      },
      []
    );

    const handleCode = (val: string) => {
      setCode(val);
    };

    const check = async () => {
      setLoading(true);
      api
        .post(`${process.env.REACT_APP_API_URL}/api/invite/check-invite-code`, {
          inviteCode: code,
        })
        .then(() => {
          setCode(undefined);
          setError(undefined);
          checkInviteCodeCallback && checkInviteCodeCallback(code);
          setOpen(false);
        })
        .catch(() => {
          setError("Current invite code doesn't exist.");
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const onClose = () => {
      setOpen(false);
      onCloseCallback.current();
      setCode(undefined);
      checkInviteCodeCallback && checkInviteCodeCallback(undefined);
    };

    return (
      <Modal title="Invite code check" open={open} footer={null} onCancel={onClose}>
        <Spin spinning={loading}>
          <p>Enter your invite code</p>
          <Input
            className={styles.inviteCodeInput}
            value={code}
            onChange={(e) => {
              handleCode(e.target.value);
            }}
          />
          {error && (
            <Typography.Text className={styles.errorMessage} type="danger">
              {error}
            </Typography.Text>
          )}
          <div>
            <Button onClick={check}>Check code</Button>
          </div>
        </Spin>
      </Modal>
    );
  }
);
