import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { PasswordInput } from "../../components/form/input/passwordInput";
import { Button } from "../../components/button";
import { api } from "../../services/httpService";
import { ACCESS_TOKEN } from "../../constants/localStorageConstants";

interface LoginBody {
  password: string;
}

const schema = yup
  .object({
    password: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Password should be more than 8 characters, with at least 1 uppercase (A-Z) or lowercase letter (a-z), at least 1 numeric character (0-9), and at least 1 special character."
      )
      .required(),
  })
  .required();

export const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<LoginBody>({
    resolver: yupResolver(schema),
    defaultValues: { password: "" },
  });
  const [resetPasswordCode, setResetPasswordCode] = useState<string>("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [confirmPasswordError, setConfirmPasswordError] = useState<string | undefined>();
  const [resetPasswordError, setResetPasswordError] = useState<string>();

  useEffect(() => {
    const code = searchParams.get("code");
    if (code && code.length > 0) {
      setResetPasswordCode(code);
    } else {
      navigate("/login", { replace: true });
    }
  }, [searchParams]);

  const checkPasswordMismatch = (value?: string) => {
    const currentConfirmPassword = value || confirmPassword;
    if (currentConfirmPassword !== getValues("password")) {
      setConfirmPasswordError("Passwords mismatch");
      return true;
    } else {
      setConfirmPasswordError("");
      return false;
    }
  };

  const handleConfirmPassword = (value: string) => {
    setConfirmPassword(value);
    if (confirmPasswordError === undefined) {
      return;
    }

    checkPasswordMismatch(value);
  };

  const onSubmit = async ({ password }: LoginBody) => {
    localStorage.removeItem(ACCESS_TOKEN);
    setIsLoading(true);
    const passwordMismatch = checkPasswordMismatch();
    if (passwordMismatch) {
      setIsLoading(false);
      return;
    }
    const result = await api.post(`${process.env.REACT_APP_API_URL}/api/auth/reset-password`, {
      password,
      passwordConfirmation: confirmPassword,
      code: resetPasswordCode,
    });

    if (result.status !== 200) {
      setResetPasswordError("Something wrong code");
    }
    if (result.status === 200) {
      localStorage.setItem(ACCESS_TOKEN, result.data.jwt);
      navigate("/");
    }

    setIsLoading(false);
  };

  return (
    <div className="formContainer">
      <h3 className="formTitle">Reset Password</h3>
      <div className="formInputs">
        <div>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { ...props } }) => <PasswordInput label="New password" {...props} />}
            name="password"
          />
          {errors.password && <Typography.Text type="danger">{errors.password.message}</Typography.Text>}
        </div>
        <div>
          <PasswordInput
            label="Confirm password"
            onChange={(e) => {
              handleConfirmPassword(e.target.value);
            }}
          />
          {confirmPasswordError && <Typography.Text type="danger">{confirmPasswordError}</Typography.Text>}
        </div>
        {resetPasswordError && <Typography.Text type="danger">{resetPasswordError}</Typography.Text>}
        <Button type="primary" loading={isLoading} onClick={handleSubmit(onSubmit)}>
          Change Password
        </Button>
      </div>
    </div>
  );
};
