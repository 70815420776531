import React, { ReactNode, useEffect, useState } from "react";
import { Button, Upload, UploadFile } from "antd";
import UploadOutlined from "@ant-design/icons/UploadOutlined";
import { api } from "../../services/httpService";

export interface UploaderFileInterface {
  uid: string;
  name: string;
  status: "done";
  url: string;
  response: {
    id: number;
  };
}
interface UploaderProps {
  name: string;
  onFilePayload: (payload: any[], name: string) => void;
  defaultFileList?: UploaderFileInterface[];
  cleanFileList?: (clean: () => void) => void;
  showUploadList?: {
    showDownloadIcon: boolean;
    downloadIcon?: ReactNode;
  };
}

const initialState = {};
export const Uploader = ({ name, onFilePayload, defaultFileList, cleanFileList }: UploaderProps) => {
  const [files, setFiles] = useState<Record<string, any>>(initialState);
  const [filesList, setFilesList] = useState<UploadFile[]>([]);

  useEffect(() => {
    cleanFileList &&
      cleanFileList(() => {
        setFilesList([]);
      });
  }, []);

  useEffect(() => {
    if (files !== initialState) {
      onFilePayload(Object.values(files), name);
    }
  }, [files]);
  const customRequest = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;

    const formData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
      onUploadProgress: (event: any) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    formData.append("files", file);
    try {
      const res = await api.post(`${process.env.REACT_APP_API_URL}/api/upload`, formData, config);

      const { name, id } = res.data[0];
      setFiles((prev) => ({ ...prev, [id]: res.data[0] }));
      onSuccess({
        name,
        id,
      });
    } catch (err) {
      onError({ err });
    }
  };

  const onRemove = async (file: any) => {
    const fileId = file?.response?.id;

    if (!fileId) {
      return;
    }
    try {
      api.delete(`${process.env.REACT_APP_API_URL}/api/upload/files/${fileId}`).finally(() => {
        setFiles((prev) => {
          const clone = { ...prev };
          delete clone[fileId];
          return clone;
        });
      });
    } catch {
      return;
    }
  };

  return (
    <Upload
      fileList={filesList}
      onChange={(e) => {
        setFilesList(e.fileList);
      }}
      customRequest={customRequest}
      defaultFileList={defaultFileList}
      onRemove={onRemove}
    >
      <Button icon={<UploadOutlined />}>Upload</Button>
    </Upload>
  );
};
