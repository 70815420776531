import { api } from "../services/httpService";

export const checkEmail = async (email: string) => {
  try {
    const result = await api.post(`${process.env.REACT_APP_API_URL}/api/user/check-email`, {
      email,
    });

    return !(result.status === 200);
  } catch (err) {
    return true;
  }
};

export const checkPhoneNumber = async (phoneNumber: string) => {
  try {
    const result = await api.post(`${process.env.REACT_APP_API_URL}/api/user/check-phone-number`, {
      phoneNumber,
    });

    return !(result.status === 200);
  } catch (err) {
    return true;
  }
};
