import React from "react";
import { ReactComponent as CurveIcon } from "../../assets/curve.svg";
import styles from "./knowMore.module.css";

interface KnowMoreProps {
  className?: string;
}

export const KnowMore = ({ className }: KnowMoreProps) => {
  return (
    <div className={className}>
      <div className={styles.knowMore}>Know more</div>
      <CurveIcon className={styles.knowMoreIcon} />
    </div>
  );
};
