import React, { useState } from "react";
import { Result, Typography } from "antd";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";
import { Overlay } from "../../components/overlay";
import { Button } from "../../components/button";
import { PageBanner } from "../../components/pageBanner";
import { Input } from "../../components/form/input";
import { Section } from "../../components/section";

import { ReactComponent as FaceSmileWinkSolid } from "../../assets/faceSmileWinkSolid.svg";
import { ReactComponent as CurveIcon } from "../../assets/curve.svg";

import styles from "./contactUs.module.css";

interface ContactUsBody {
  subject: string;
  email: string;
  firstName: string;
  lastName: string;
  message: string;
}

const schema = yup
  .object({
    subject: yup.string().required("Subject required"),
    email: yup
      .string()
      .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, "The email is not valid.")
      .required(),
    firstName: yup.string().required("First name required."),
    lastName: yup.string().required("Last name required."),
    message: yup.string().required("Message required."),
  })
  .required();

export const ContactUs = () => {
  const [success, setSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<ContactUsBody>({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      subject: "",
      message: "",
    },
  });

  const closeOverlay = () => {
    setSuccess(false);

    setValue("firstName", "");
    setValue("lastName", "");
    setValue("email", "");
    setValue("subject", "");
    setValue("message", "");
  };

  const onSubmit = () => {
    setIsLoading(true);
    setSuccess(true);

    setIsLoading(false);
  };

  return (
    <div>
      {success && (
        <Overlay>
          <Result
            className={styles.successResult}
            icon={<FaceSmileWinkSolid className={styles.smileIcon} />}
            title="Message sent!"
            extra={
              <Button type="primary" onClick={closeOverlay}>
                Ok
              </Button>
            }
          />
        </Overlay>
      )}
      <PageBanner
        title="Contact Us"
        description="Beatae vitae dicta sunt explicabo. Nemo enim ipsam"
        className={styles.banner}
      />
      <div className={styles.container}>
        <Section>
          <div className={styles.formContainer}>
            <div className={styles.form}>
              <div className={styles.formWelcome}>Welcome</div>
              <CurveIcon className={styles.formCurve} />
              <h4 className={styles.formTitle}>Fill the Contact Us Form</h4>
              <div className={styles.formInputs}>
                <div className={styles.usernameBlock}>
                  <div className={styles.firstName}>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { ...props } }) => (
                        <Input error={!!errors.firstName} label="First name" disabled={isLoading} {...props} />
                      )}
                      name="firstName"
                    />
                    {errors.firstName && <Typography.Text type="danger">{errors.firstName.message}</Typography.Text>}
                  </div>
                  <div className={styles.lastName}>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { ...props } }) => (
                        <Input error={!!errors.lastName} label="Last name" disabled={isLoading} {...props} />
                      )}
                      name="lastName"
                    />
                    {errors.lastName && <Typography.Text type="danger">{errors.lastName.message}</Typography.Text>}
                  </div>
                </div>

                <div>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { ...props } }) => (
                      <Input error={!!errors.email} label="Email" type="email" disabled={isLoading} {...props} />
                    )}
                    name="email"
                  />
                  {errors.email && <Typography.Text type="danger">{errors.email.message}</Typography.Text>}
                </div>
                <div>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { ...props } }) => (
                      <Input error={!!errors.subject} label="Subject" disabled={isLoading} {...props} />
                    )}
                    name="subject"
                  />
                  {errors.subject && <Typography.Text type="danger">{errors.subject.message}</Typography.Text>}
                </div>
                <div>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { ...props } }) => (
                      <Input
                        multiline={true}
                        lines={5}
                        error={!!errors.message}
                        label="Message"
                        disabled={isLoading}
                        {...props}
                      />
                    )}
                    name="message"
                  />
                  {errors.message && <Typography.Text type="danger">{errors.message.message}</Typography.Text>}
                </div>
              </div>
              <Button
                disabled={isLoading}
                type="primary"
                className={styles.formSubmit}
                onClick={handleSubmit(onSubmit)}
              >
                {isLoading ? <LoadingOutlined /> : "Send"}
              </Button>
            </div>
          </div>
        </Section>
      </div>
    </div>
  );
};
