import React from "react";
import { Route } from "react-router-dom";
import { Login } from "./pages/login";
import { SignUp } from "./pages/signUp";
import { Layout } from "./components/layout";
import { Home } from "./pages/home";
import { UserPersonalData } from "./pages/signUp/signUpSteps/userPersonalData";
import { UserDocuments } from "./pages/signUp/signUpSteps/userDocuments";
import { UserSSNInfo } from "./pages/signUp/signUpSteps/userSSNInfo";
import { ForgotPassword } from "./pages/forgotPassword";
import { ResetPassword } from "./pages/resetPassword";
import { AboutUs } from "./pages/aboutUs";
import { Profile } from "./pages/profile";
import { JoinUs } from "./pages/joinUs";
import { WhatWeDo } from "./pages/whatWeDo";
import { ContactUs } from "./pages/contactUs";
import { Blog } from "./pages/blog";
import { ProtectedRoute, ProtectedRouteWithCustomLogic } from "./providers/authProvider";
import { PrivacyPolicy } from "./pages/privacyPolicy";

const routes = (
  <Route path="/">
    <Route element={<Layout />}>
      <Route
        path="/"
        element={
          <ProtectedRoute authenticated={true}>
            <Home />
          </ProtectedRoute>
        }
      />
      <Route path="/what-we-do" element={<WhatWeDo />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/join-us" element={<JoinUs />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/blog" element={<Blog />} />
      <Route
        path="/profile"
        element={
          <ProtectedRoute authenticated={true}>
            <ProtectedRouteWithCustomLogic
              redirect="/"
              check={(user) => !!(user?.ssnInfo && user?.documentsRegistered)}
            >
              <Profile />
            </ProtectedRouteWithCustomLogic>
          </ProtectedRoute>
        }
      />
      <Route path="/sign-up">
        <Route element={<SignUp />}>
          <Route path="/sign-up/" element={<UserPersonalData />} />
          <Route
            path="/sign-up/documents"
            element={
              <ProtectedRoute authenticated={true}>
                <UserDocuments />
              </ProtectedRoute>
            }
          />
          <Route
            path="/sign-up/ssn-info"
            element={
              <ProtectedRoute authenticated={true}>
                <UserSSNInfo />
              </ProtectedRoute>
            }
          />
        </Route>
      </Route>
    </Route>
  </Route>
);

export default routes;
