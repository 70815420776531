import React, { useState } from "react";
import EyeInvisibleOutlined from "@ant-design/icons/EyeInvisibleOutlined";
import EyeOutlined from "@ant-design/icons/EyeOutlined";
import { Input, InputProps } from "../index";
import inputStyles from "../input.module.css";

export const PasswordInput = ({ ...props }: Exclude<InputProps, "extra" | "type">) => {
  const [visible, setVisible] = useState<boolean>(false);

  const toggleVisible = () => {
    setVisible(!visible);
  };

  return (
    <Input
      {...props}
      type={visible ? "text" : "password"}
      extra={
        <div className={inputStyles.visibleControlButton} onClick={toggleVisible}>
          {visible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
        </div>
      }
    />
  );
};
