import React from "react";
import cn from "classnames";
import { ReactComponent as CurveIcon } from "../../assets/curve.svg";
import styles from "./pageBanner.module.css";

interface PageBannerProps {
  title: string;
  description: string;
  className?: string;
}

export const PageBanner = ({ title, description, className }: PageBannerProps) => {
  return (
    <div className={cn(styles.banner, className)}>
      <div className={styles.bannerWelcome}>Welcome to Usoptool</div>
      <CurveIcon />
      <h4 className={styles.bannerTitle}>{title}</h4>
      <p className={styles.bannerDescription}>{description}</p>
    </div>
  );
};
