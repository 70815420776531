import React from "react";
import { KnowMore } from "../../../components/knowMore";
import { Section } from "../../../components/section";

import ServiceTextImage from "../../../assets/home/ourService/serviceText.svg";
import AnalyticsIcon from "../../../assets/home/ourService/analyticsIcon.svg";
import StrategyIcon from "../../../assets/home/ourService/strategyIcon.svg";
import DataAnalysisIcon from "../../../assets/home/ourService/customerDataAnalysis.svg";
import TransparentReportingIcon from "../../../assets/home/ourService/transparentReporting.svg";
import SupportIcon from "../../../assets/home/ourService/support.svg";
import CustomSolutionsIcon from "../../../assets/home/ourService/customSolutions.svg";

import styles from "./ourService.module.css";

interface ServiceItem {
  img: string;
  title: string;
  description: string;
}

const services: ServiceItem[] = [
  {
    img: AnalyticsIcon,
    title: "Customer Data Analysis",
    description:
      "Unlock the power of your player data. Our advanced analytics tools delve deep into your customer database to uncover invaluable insights. Understand player preferences and",
  },
  {
    img: StrategyIcon,
    title: "Marketing Strategy Optimization",
    description:
      "Craft winning marketing strategies that deliver results. Our team of experts collaborates with you to develop data-driven",
  },
  {
    img: DataAnalysisIcon,
    title: "Regulatory Compliance",
    description:
      "Stay ahead of the regulatory curve. Our compliance experts ensure that your marketing practices align with industry regulations and ethical standards.",
  },
  {
    img: TransparentReportingIcon,
    title: "Transparent Reporting",
    description:
      "Transparency is key to informed decision-making. Our transparent reporting practices provide you with clear, actionable insights. We communicate findings",
  },
  {
    img: SupportIcon,
    title: "Continuous Support and Optimization",
    description:
      "Our commitment doesn't end with the delivery of insights and strategies. We offer ongoing support to monitor",
  },
  {
    img: CustomSolutionsIcon,
    title: "Custom Solutions",
    description:
      "Every casino is unique, and so are its challenges. If you have specific needs or ideas, talk to us about custom solutions. We're here to tailor our services.",
  },
];

export const OurService = () => {
  return (
    <div className={styles.container}>
      <Section className={styles.innerContainer}>
        <div className={styles.header}>
          <img src={ServiceTextImage} alt="Our service" className={styles.headerImage} />
          <KnowMore className={styles.knowMore} />
          <h3 className={styles.headerTitle}>Our Services</h3>
          <p className={styles.headerDescription}>
            Beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut
            fugit.
          </p>
        </div>
        <div className={styles.content}>
          {services.map((service, index) => (
            <div className={styles.card} key={index}>
              <div>
                <div className={styles.cardImageWrapper}>
                  <img className={styles.cardImage} src={service.img} alt={service.title} />
                </div>

                <h6 className={styles.cardTitle}>{service.title}</h6>
                <p className={styles.cardDescription}>{service.description}</p>
              </div>
              <span className={styles.cardReadMore}>Read more {">>"}</span>
            </div>
          ))}
        </div>
      </Section>
    </div>
  );
};
