import React, { useState } from "react";
import { Modal, Typography } from "antd";
import CloseOutlined from "@ant-design/icons/CloseOutlined";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Input } from "../../components/form/input";
import { Button } from "../../components/button";
import { api } from "../../services/httpService";
import { ACCESS_TOKEN } from "../../constants/localStorageConstants";
import { BackButton } from "../../components/backButton";
import styles from "./forgotPassword.module.css";

interface ForgotPasswordBody {
  email: string;
}

const schema = yup
  .object({
    email: yup
      .string()
      .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, "Wrong email")
      .required(),
  })
  .required();

export const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [resetSuccess, setResetSuccess] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordBody>({
    resolver: yupResolver(schema),
    defaultValues: { email: "" },
  });
  const [wrongEmail, setWrongEmail] = useState<boolean>(false);
  const navigate = useNavigate();
  const onSubmit = async ({ email }: ForgotPasswordBody) => {
    localStorage.removeItem(ACCESS_TOKEN);
    setIsLoading(true);
    const result = await api.post(`${process.env.REACT_APP_API_URL}/api/auth/forgot-password`, {
      email,
    });

    setWrongEmail(result.status !== 200);
    setResetSuccess(result.status === 200);

    setIsLoading(false);
  };

  const onClose = () => {
    navigate("/login");
  };

  return (
    <div className="formContainer">
      <Modal title="Information" open={resetSuccess} footer={null} closeIcon={null} onCancel={onClose}>
        <CloseOutlined onClick={onClose} className={styles.closeIcon} />
        <p>We have sent you a letter to your email with a reset password link.</p>
      </Modal>
      <BackButton />
      <h3 className="formTitle">Forgot Password</h3>
      <div className="formInputs">
        <div>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { ...props } }) => (
              <Input required={true} label="Email" disabled={isLoading} {...props} />
            )}
            name="email"
          />
          {errors.email && <Typography.Text type="danger">{errors.email.message}</Typography.Text>}
        </div>
        {wrongEmail && <Typography.Text type="danger">Wrong email data</Typography.Text>}
        <Button type="primary" loading={isLoading} onClick={handleSubmit(onSubmit)}>
          Send
        </Button>
      </div>
    </div>
  );
};
