import React from "react";
import { Section } from "../../../components/section";
import { Button } from "../../../components/button";
import { KnowMore } from "../../../components/knowMore";

import { ReactComponent as TransformTextIcon } from "../../../assets/home/transform/transformText.svg";
import { ReactComponent as LeftGameCubeIcon } from "../../../assets/gameCubeV2.svg";
import TransformImage from "../../../assets/home/transform/transformImage.png";

import styles from "./transform.module.css";

export const Transform = () => {
  return (
    <>
      <div className={styles.container}>
        <Section>
          <div className={styles.content}>
            <TransformTextIcon className={styles.containerTitle} />
            <div className={styles.contentImageBlock}>
              <img className={styles.contentImage} src={TransformImage} alt="Transform image" />
            </div>

            <div className={styles.contentBody}>
              <KnowMore className={styles.knowMore} />
              <h3 className={styles.contentTitle}>Let's Transform Your Casino Marketing</h3>
              <p className={styles.contentDescription}>
                Join Us and take your casino marketing to the next level. Together, we'll shape a future of success for
                your casino.
              </p>
            </div>
          </div>
        </Section>
      </div>
      <div className={styles.getStarted}>
        <LeftGameCubeIcon className={styles.getStartedGameCube} />
        <div className={styles.getStartedInner}>
          <p className={styles.getStartedDescription}>
            Ready to get started? Contact Us today, and let's embark on your journey toward data-driven casino marketing
            excellence.
          </p>
          <Button type="primary" className={styles.letsConnect}>
            Let's Connect
          </Button>
        </div>
      </div>
    </>
  );
};
