import { JOIN_US_FORM_DATA } from "../constants/localStorageConstants";

export interface JoinUsBody {
  firstName: string;
  surName: string;
  email: string;
  phoneNumber: string;
}

export const getJoinUsRegisterBody = (): JoinUsBody | undefined => {
  const data = localStorage.getItem(JOIN_US_FORM_DATA);
  if (!data) {
    return;
  }

  const parsedData = JSON.parse(data);

  const result = {
    firstName: parsedData?.firstName || "",
    surName: parsedData?.surName || "",
    email: parsedData?.email || "",
    phoneNumber: parsedData?.phoneNumber || "",
  };

  const emptyCount = Object.values(result).filter((item) => item.length === 0).length;
  return emptyCount === 0 ? result : undefined;
};

export const saveJoinUsRegisterBody = (body: JoinUsBody) => {
  localStorage.setItem(JOIN_US_FORM_DATA, JSON.stringify(body));
};
