import React from "react";
import { KnowMore } from "../../../components/knowMore";
import { List } from "../../../components/list";
import { Section } from "../../../components/section";

import { ReactComponent as LeftGameCubeIcon } from "../../../assets/gameCubeV1.svg";
import { ReactComponent as RightGameCubeIcon } from "../../../assets/gameCubeV3.svg";
import WhyChooseUsImage from "../../../assets/home/whyChooseUs/whyChooseUsImage.png";
import { ReactComponent as WhyChooseUsText } from "../../../assets/home/whyChooseUs/whyChooseUsText.svg";

import styles from "./whyChooseUs.module.css";

export const WhyChooseUs = () => {
  return (
    <div className={styles.container}>
      <Section>
        <div className={styles.content}>
          <LeftGameCubeIcon className={styles.leftGameCube} />
          <RightGameCubeIcon className={styles.rightGameCube} />
          <div className={styles.overlay} />
          <div className={styles.contentBlock}>
            <WhyChooseUsText className={styles.whyChooseUsText} />
            <div className={styles.imageContainer}>
              <img className={styles.image} src={WhyChooseUsImage} alt="Why choose us image" />
            </div>
            <div className={styles.contentBlockBody}>
              <KnowMore />
              <h3 className={styles.contentBlockBodyTitle}>Why Choose Us</h3>
              <p className={styles.contentBlockBodyDescription}>
                Beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut
                fugit.
              </p>
              <List
                className={styles.list}
                style="dot"
                items={[
                  { key: "1", label: "Specialized Expertise" },
                  { key: "2", label: "Data-Driven Insights" },
                  { key: "3", label: "Ethical and Transparent" },
                  { key: "4", label: "Tailored Strategies" },
                ]}
              />
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
};
