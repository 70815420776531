import React from "react";
import { KnowMore } from "../../components/knowMore";
import { WhatWeDoCard } from "./whatWeDoCard";
import { PageBanner } from "../../components/pageBanner";
import TargetImage from "../../assets/targetImage.png";
import FooterImage from "../../assets/whatWeDo/whatWeDoFooterImage.png";
import styles from "./whatWeDo.module.css";
import { Section } from "../../components/section";

interface WhatWeDoItem {
  iconSrc: string;
  title: string;
  description: string;
}

const whatWeDoItems: WhatWeDoItem[] = [
  {
    iconSrc: TargetImage,
    title: "Comprehending Customer Behavior",
    description:
      "Our mission begins with a commitment to understanding the intricacies of customer behaviour. We believe that each player is unique, and by collecting data on various marketing aspects, we can gain valuable insights into their preferences, spending patterns, and demographics. This foundational knowledge allows us to customize marketing campaigns tailored to specific target groups, expanding your reach and resonance within your audience.",
  },
  {
    iconSrc: TargetImage,
    title: "Personalized Offers and Incentives",
    description:
      "Armed with an in-depth analysis of customer behaviour, including factors such as time spent playing, favoured games, and preferred amenities, we craft personalized offers and incentives. These aren't just promotions; they are experiences designed to resonate with your audience. From exclusive game promotions to tailored amenities, we aim to create a casino environment that truly speaks to the hearts of your patrons.",
  },
  {
    iconSrc: TargetImage,
    title: "Clarity as a Core Principle",
    description:
      "In the world of casino marketing research, transparency is paramount. We hold ourselves to the highest standards of openness and accountability. Our dedication to ethical and compliant data collection and analysis methods ensures that we adhere to industry standards. The findings of our research are always shared with relevant stakeholders, including casino operators and marketing teams, fostering an environment of informed decision-making and trust.",
  },
  {
    iconSrc: TargetImage,
    title: "Empowering Informed Decision-Making",
    description:
      "Our role doesn't stop at data collection and analysis. We're here to empower you, casino operators and marketing teams, with actionable insights. We provide the knowledge you need to make informed decisions that drive your success. Your objectives are our focus, and we're here to help you achieve them.",
  },
];

export const WhatWeDo = () => {
  return (
    <div>
      <PageBanner
        className={styles.banner}
        title="What We Do"
        description="Beatae vitae dicta sunt explicabo. Nemo enim ipsam"
      />
      <div className={styles.container}>
        <Section>
          <div className={styles.containerHeader}>
            <KnowMore className={styles.knowMore} />
            <h4 className={styles.containerTitle}>What We Do</h4>
            <p className={styles.containerDescription}>
              Our core services revolve around the triad of data collection, analysis, and transparency. By
              understanding customer preferences and behaviours, we aim to enhance your marketing efforts, delivering a
              more tailored and rewarding experience to your patrons. Join us and embark on a journey to harness the
              power of data and redefine your casino's success. Your patrons deserve the best, and together, we'll
              ensure they get it.
            </p>
          </div>

          <div className={styles.containerBody}>
            {whatWeDoItems.map((item, index) => (
              <WhatWeDoCard key={index} {...item} />
            ))}
          </div>
        </Section>
      </div>
      <div className={styles.footer}>
        <div className={styles.footerInnerContainer}>
          <div className={styles.footerBody}>
            <h4 className={styles.footerTitle}>Together,</h4>
            <p className={styles.footerDescription}>
              We'll embark on a journey to harness the power of data and redefine the success of your casino.
            </p>
          </div>
          <div className={styles.footerImageContainer}>
            <img className={styles.footerImage} src={FooterImage} alt="footer image" />
          </div>
        </div>
      </div>
    </div>
  );
};
