import React, { ReactNode } from "react";
import styles from "../footer.module.css";

interface ContentBlockItem {
  key: string;
  label: ReactNode | string;
}

interface ContentBlockItemProps {
  label: string | ReactNode;
}

interface ContentBlock {
  title: string;
  items: ContentBlockItem[];
  footer?: ReactNode;
}

export const ContentBlock = ({ title, footer, items }: ContentBlock) => {
  return (
    <div className={styles.contentBlock}>
      <div className={styles.contentBlockTitle}>{title}</div>
      {items.map((item) =>
        typeof item.label === "string" ? (
          <ContentBlockItem key={item.key} label={item.label} />
        ) : (
          <React.Fragment key={item.key}>{item.label}</React.Fragment>
        )
      )}
      {footer}
    </div>
  );
};

export const ContentBlockItem = ({ label }: ContentBlockItemProps) => {
  return <div className={styles.contentBlockItem}>{label}</div>;
};
