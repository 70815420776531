import React from "react";
import { Button } from "antd";
import cn from "classnames";
import { useNavigate } from "react-router-dom";
import ArrowLeftOutlined from "@ant-design/icons/ArrowLeftOutlined";
import styles from "./backButton.module.css";

interface BackButtonProps {
  className?: string;
}

export const BackButton = ({ className }: BackButtonProps) => {
  const navigate = useNavigate();
  const back = () => {
    navigate(-1);
  };

  return (
    <Button
      className={cn(styles.btn, className)}
      icon={<ArrowLeftOutlined className={styles.btnIcon} />}
      shape="circle"
      onClick={back}
    />
  );
};
