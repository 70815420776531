import React from "react";
import { Row } from "antd";
import { SocialButton } from "../../socialButton";
import { Link } from "../../link";

import { ReactComponent as LeftIcon } from "../../../assets/gameCubeV1.svg";
import { ReactComponent as RightIcon } from "../../../assets/gameCubeV3.svg";
import { ReactComponent as MailIcon } from "../../../assets/mail.svg";
import { ReactComponent as PhoneIcon } from "../../../assets/phone.svg";

import styles from "./homeTop.module.css";

export const HomeTop = () => {
  return (
    <Row justify="center" wrap={false} align="middle" className={styles.homePageTopContainer}>
      <LeftIcon className={styles.leftIcon} />
      <Row wrap={false} align="middle" className={styles.contacts}>
        <Link to="tel:+9198765-43210" className={styles.contactContainer}>
          <Row wrap={false} align="middle" className={styles.contactItem}>
            <PhoneIcon className={styles.contactItemIcon} />
            <span>+9198765-43210</span>
          </Row>
        </Link>
        <Link to="mailto:Usoptool@gmail.com" className={styles.contactContainer}>
          <Row wrap={false} align="middle" className={styles.contactItem}>
            <MailIcon className={styles.contactItemIcon} />
            <span>Usoptool@gmail.com</span>
          </Row>
        </Link>

        <div className={styles.socials}>
          <SocialButton type="facebook" className={styles.contactItemIcon} />
          <SocialButton type="twitter" className={styles.contactItemIcon} />
          <SocialButton type="instagram" className={styles.contactItemIcon} />
          <SocialButton type="google" className={styles.contactItemIcon} />
          <SocialButton type="telegram" className={styles.contactItemIcon} />
        </div>
      </Row>
      <RightIcon className={styles.rightIcon} />
    </Row>
  );
};
