import React from "react";
import { Row } from "antd";
import { ContentBlock, ContentBlockItem } from "./contentBlock";
import { Link } from "../../link";
import { SocialButton } from "../../socialButton";
import { Section } from "../../section";

import { ReactComponent as MailIcon } from "../../../assets/mail.svg";
import { ReactComponent as PhoneIcon } from "../../../assets/phone.svg";

import styles from "./footer.module.css";

export const Footer = () => {
  return (
    <div>
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <Section className={styles.section}>
            <ContentBlock
              title="About Us"
              items={[
                {
                  key: "1",
                  label:
                    "We empower casinos to navigate the complex landscape of marketing with precision and confidence.",
                },
                {
                  key: "2",
                  label: (
                    <div>
                      <div className={styles.divider} />
                      <ContentBlockItem
                        label={
                          <Link to="tel:+9198765-43210">
                            <Row wrap={false} align="middle" className={styles.contactItem}>
                              <PhoneIcon className={styles.contactIcon} />
                              <span>+9198765-43210</span>
                            </Row>
                          </Link>
                        }
                      />
                      <ContentBlockItem
                        label={
                          <Link to="mailto:Usoptool@gmail.com">
                            <Row wrap={false} align="middle" className={styles.contactItem}>
                              <MailIcon className={styles.contactIcon} />
                              <span>Usoptool@gmail.com</span>
                            </Row>
                          </Link>
                        }
                      />
                      <ContentBlockItem label={<div className={styles.followUs}>Follow Us:</div>} />

                      <Row wrap={false} align="middle" className={styles.socials}>
                        <SocialButton type="facebook" className={styles.contactIcon} />
                        <SocialButton type="twitter" className={styles.contactIcon} />
                        <SocialButton type="instagram" className={styles.contactIcon} />
                        <SocialButton type="google" className={styles.contactIcon} />
                        <SocialButton type="telegram" className={styles.contactIcon} />
                      </Row>
                    </div>
                  ),
                },
              ]}
            />
            <ContentBlock
              title="Services"
              items={[
                {
                  key: "1",
                  label: (
                    <ContentBlockItem
                      label={
                        <Link className={styles.link} to="#">
                          Customer Data Analysis
                        </Link>
                      }
                    />
                  ),
                },
                {
                  key: "2",
                  label: (
                    <ContentBlockItem
                      label={
                        <Link className={styles.link} to="#">
                          Marketing Strategy Optimization
                        </Link>
                      }
                    />
                  ),
                },
                {
                  key: "3",
                  label: (
                    <ContentBlockItem
                      label={
                        <Link className={styles.link} to="#">
                          Regulatory Compliance
                        </Link>
                      }
                    />
                  ),
                },
                {
                  key: "4",
                  label: (
                    <ContentBlockItem
                      label={
                        <Link className={styles.link} to="#">
                          Transparent Reporting
                        </Link>
                      }
                    />
                  ),
                },
                {
                  key: "5",
                  label: (
                    <ContentBlockItem
                      label={
                        <Link className={styles.link} to="#">
                          Custom Solution
                        </Link>
                      }
                    />
                  ),
                },
              ]}
            />
            <ContentBlock
              title="Quick Links"
              items={[
                {
                  key: "1",
                  label: (
                    <ContentBlockItem
                      label={
                        <Link className={styles.link} to="/">
                          Home
                        </Link>
                      }
                    />
                  ),
                },
                {
                  key: "2",
                  label: (
                    <ContentBlockItem
                      label={
                        <Link className={styles.link} to="/about-us">
                          About Us
                        </Link>
                      }
                    />
                  ),
                },
                {
                  key: "3",
                  label: (
                    <ContentBlockItem
                      label={
                        <Link className={styles.link} to="/what-we-do">
                          What we do
                        </Link>
                      }
                    />
                  ),
                },
                {
                  key: "4",
                  label: (
                    <ContentBlockItem
                      label={
                        <Link className={styles.link} to="/contact-us">
                          Contact Us
                        </Link>
                      }
                    />
                  ),
                },
              ]}
            />
            <ContentBlock
              title="Industries"
              items={[
                {
                  key: "1",
                  label: (
                    <ContentBlockItem
                      label={
                        <Link className={styles.link} to="#">
                          B2B Technology
                        </Link>
                      }
                    />
                  ),
                },
                {
                  key: "2",
                  label: (
                    <ContentBlockItem
                      label={
                        <Link className={styles.link} to="#">
                          Home builders
                        </Link>
                      }
                    />
                  ),
                },
                {
                  key: "3",
                  label: (
                    <ContentBlockItem
                      label={
                        <Link className={styles.link} to="#">
                          Franchise and Retail
                        </Link>
                      }
                    />
                  ),
                },
                {
                  key: "4",
                  label: (
                    <ContentBlockItem
                      label={
                        <Link className={styles.link} to="#">
                          Casinos and Games
                        </Link>
                      }
                    />
                  ),
                },
                {
                  key: "5",
                  label: (
                    <ContentBlockItem
                      label={
                        <Link className={styles.link} to="#">
                          Higher Education
                        </Link>
                      }
                    />
                  ),
                },
              ]}
            />
          </Section>
        </div>
      </div>
      <div className={styles.allRights}>
        <Link className={styles.allRightsLink} to="/privacy-policy">
          Privacy policy
        </Link>
        <span className={styles.allRightsDividerLine}>/</span>
        <span className={styles.allRightsCompanyName}>Usoptool</span>
        <span>© 2023. All Rights Reserved</span>
      </div>
    </div>
  );
};
