import React from "react";
import { WhoWeAreBlock } from "../../components/whoWeAreBlock";
import { AboutUsSection } from "./aboutUsSection";
import { PageBanner } from "../../components/pageBanner";
import { Section } from "../../components/section";

import FirstSectionImage from "../../assets/aboutUs/aboutUsSectionImage1.png";
import SecondSectionImage from "../../assets/aboutUs/aboutUsSectionImage2.png";
import ThirdSectionImage from "../../assets/aboutUs/aboutUsSectionImage3.png";
import FourthSectionImage from "../../assets/aboutUs/aboutUsSectionImage4.png";

import FirstMobileSectionImage from "../../assets/aboutUs/aboutUsMobileSectionImage1.png";
import SecondMobileSectionImage from "../../assets/aboutUs/aboutUsMobileSectionImage2.png";
import ThirdMobileSectionImage from "../../assets/aboutUs/aboutUsMobileSectionImage3.png";
import FourthMobileSectionImage from "../../assets/aboutUs/aboutUsMobileSectionImage4.png";

import styles from "./aboutUs.module.css";

export const AboutUs = () => {
  return (
    <div>
      <PageBanner
        className={styles.banner}
        title="About Us"
        description="Beatae vitae dicta sunt explicabo. Nemo enim ipsam"
      />
      <WhoWeAreBlock />
      <div className={styles.section}>
        <Section>
          <AboutUsSection
            title="Understanding Your Players"
            description="At the heart of our approach is the understanding that each player is unique. We delve into the depths of customer data, exploring preferences, spending patterns, and demographics. This invaluable information empowers us to tailor marketing campaigns to specific target groups, ensuring a resonance that goes beyond the gaming floor."
            imageSrc={FirstSectionImage}
            mobileImageSrc={FirstMobileSectionImage}
            bodyClassName={styles.sectionBody}
          />
        </Section>
      </div>
      <div className={styles.reverseSection}>
        <Section>
          <AboutUsSection
            title="Crafting Personalized Experiences"
            description="Armed with insights into customer behaviour – the time spent playing, favoured games, and preferred amenities – we create personalized offers and incentives. These aren't just promotions; they are crafted experiences that speak directly to the hearts of your audience. From exclusive game promotions to tailored amenities, we pave the way for a more engaging and rewarding casino journey."
            imageSrc={SecondSectionImage}
            mobileImageSrc={SecondMobileSectionImage}
            reverse={true}
            bodyClassName={styles.reverseSectionBody}
          />
        </Section>
      </div>
      <div className={styles.section}>
        <Section>
          <AboutUsSection
            title="Transparency as a Foundation"
            description="In the realm of casino marketing, transparency is non-negotiable. We pride ourselves on being open and accountable to regulatory bodies and customers alike. Our commitment to ethical and compliant data collection and analysis methods aligns with industry standards, ensuring a foundation built on trust. The findings of our research are shared with all relevant stakeholders, fostering an environment of informed decision-making."
            imageSrc={ThirdSectionImage}
            mobileImageSrc={ThirdMobileSectionImage}
            bodyClassName={styles.sectionBody}
          />
        </Section>
      </div>
      <div className={styles.reverseSection}>
        <Section>
          <AboutUsSection
            title="Elevate Your Casino Experience"
            description="our journey is defined by the trio of data collection, analysis, and transparency. By understanding customer preferences and behaviors, we elevate casino marketing to new heights. Join us at [Your Casino's Name] as we redefine the standards of personalized, transparent, and engaging casino experiences. Your patrons deserve nothing less, and together, we'll unlock the doors to unparalleled success."
            imageSrc={FourthSectionImage}
            mobileImageSrc={FourthMobileSectionImage}
            reverse={true}
            bodyClassName={styles.reverseSectionBody}
          />
        </Section>
      </div>
    </div>
  );
};
