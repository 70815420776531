import React, { ReactNode, useState } from "react";
import { Button, Col, notification, Row, Spin } from "antd";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import DownloadOutlined from "@ant-design/icons/DownloadOutlined";
import UploadOutlined from "@ant-design/icons/UploadOutlined";
import { DocumentRemoteFile } from "../../types/document";
import { api } from "../../services/httpService";

export interface FileManagerFileInterface {
  uid: string;
  name?: string;
  url?: string;
  missing: boolean | null;
  fileId?: number;
}

interface FileManagerProps {
  file: FileManagerFileInterface;
  onRemove: () => void;
  prefixComponent?: ReactNode;
  onMissingDocument?: (file: any) => void;
}
export const FileManager = ({ prefixComponent, file, onRemove, onMissingDocument }: FileManagerProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [missingFile, setMissingFile] = useState<DocumentRemoteFile | undefined>();
  const [notificationApi, contextHolder] = notification.useNotification();
  const onDownload = (e: React.MouseEvent<HTMLElement>) => {
    const url = file?.url;
    if (!url) {
      return;
    }
    e.stopPropagation();

    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.click();

    a.remove();
  };

  const deleteUploadedFile = async (fileId: number | undefined) => {
    try {
      await api.delete(`${process.env.REACT_APP_API_URL}/api/upload/files/${fileId}`);
    } catch {}
  };
  const removeHandler = async () => {
    setLoading(false);
    api
      .delete(`${process.env.REACT_APP_API_URL}/api/documents/${file.uid}`)
      .catch(() => {
        console.log("document not found");
      })
      .finally(async () => {
        await deleteUploadedFile(file?.fileId);
        onRemove();
        setLoading(false);
      });
  };

  const onUpload = () => {
    setLoading(true);
    const fileInput = document.createElement("input");
    fileInput.type = "file";

    const handler = async () => {
      const fileList = fileInput.files;
      if (fileList && fileList.length !== 0) {
        const formData = new FormData();
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        formData.append("files", fileList[0]);
        api
          .post(`${process.env.REACT_APP_API_URL}/api/upload`, formData, config)
          .then(({ data }) => {
            setMissingFile(data[0]);
            onMissingDocument && onMissingDocument(data[0]);
          })
          .catch(() => {
            notificationApi.error({
              message: "Error",
              description: "Something went wrong. Try again.",
            });
          })
          .finally(() => {
            setLoading(false);
            fileInput.remove();
          });

        fileInput.removeEventListener("change", handler);
      }
    };
    fileInput.addEventListener("change", handler);
    fileInput.oncancel = () => {
      fileInput.removeEventListener("change", handler);
      fileInput.remove();
    };
    fileInput.click();
  };

  const deleteMissingUploadedFile = async () => {
    setLoading(true);
    await deleteUploadedFile(missingFile?.id);
    setMissingFile(undefined);
    setLoading(false);
    onMissingDocument && onMissingDocument(undefined);
  };

  return (
    <>
      {contextHolder}
      <Spin spinning={loading}>
        <Row justify="space-between" align="middle" wrap={false} style={{ cursor: "pointer" }}>
          {prefixComponent}
          <Col flex="auto">
            <Row justify="space-between" align="middle" wrap={false}>
              <Col flex="auto">
                <p>{file.missing ? missingFile?.name || file.name : file.name}</p>
              </Col>
              <Col>
                {file.missing ? (
                  <div>
                    {missingFile ? (
                      <Button
                        onClick={deleteMissingUploadedFile}
                        type="primary"
                        shape="circle"
                        icon={<DeleteOutlined />}
                        size="small"
                      />
                    ) : (
                      <Button onClick={onUpload} type="primary" shape="circle" icon={<UploadOutlined />} size="small" />
                    )}
                  </div>
                ) : (
                  <Row wrap={false} align="middle">
                    <Button
                      onClick={onDownload}
                      type="primary"
                      shape="circle"
                      icon={<DownloadOutlined />}
                      size="small"
                    />
                    <Button
                      onClick={removeHandler}
                      type="primary"
                      shape="circle"
                      icon={<DeleteOutlined />}
                      size="small"
                    />
                  </Row>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    </>
  );
};
