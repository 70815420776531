import cn from "classnames";
import styles from "./list.module.css";

interface ListItem {
  label: string;
  key: string;
}

type ListStyle = "dot" | "none";

interface ListProps {
  items: ListItem[];
  style?: ListStyle;
  className?: string;
}

const listStyles: Record<ListStyle, string> = {
  dot: styles.listItemSignDot,
  none: styles.listItemSignNone,
};

export const List = ({ items, style = "none", className }: ListProps) => {
  return (
    <div className={cn(styles.list, className)}>
      {items.map((item) => (
        <div key={item.key} className={styles.item}>
          <span className={listStyles[style]} />
          {item.label}
        </div>
      ))}
    </div>
  );
};
