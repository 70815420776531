import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, notification, Spin, Typography } from "antd";
import { Document, DocumentType } from "../../../types/document";
import { FileManagerFileInterface } from "../../../components/fileManager";
import { DocumentsManager, FilePayloadItemInterface } from "./documentsManager";
import { api } from "../../../services/httpService";
import styles from "./userProfile.module.css";

const initialState = {};
export const UserProfile = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [documents, setDocuments] = useState<Document[]>([]);

  const [uploadedFiles, setUploadedFiles] = useState<Record<string, FilePayloadItemInterface[]>>(initialState);
  const [error, setError] = useState<string>();
  const [notificationApi, contextHolder] = notification.useNotification();
  const uploaderCleanFunctions = useRef<Record<string, () => void>>({});

  useEffect(() => {
    if (initialState === uploadedFiles) {
      return;
    }

    if (Object.keys(uploadedFiles).length === 0 && documents.length === 0) {
      setError("You need to upload at least one document.");
    } else {
      setError(undefined);
    }
  }, [uploadedFiles]);

  const onFilePayload = (payload: FilePayloadItemInterface[], name: string) => {
    setUploadedFiles((prev) => {
      if (payload.length === 0) {
        const clone = { ...prev };
        delete clone[name];

        return clone;
      } else {
        return { ...prev, [name]: payload };
      }
    });
  };

  const updateDocuments = () => {
    api
      .get<Document[]>(`${process.env.REACT_APP_API_URL}/api/documents`)
      .then((res) => {
        const documents = res.data;

        setDocuments(documents);
      })
      .catch(() => {
        setDocuments([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    updateDocuments();
  }, []);

  const defaultFileList = useMemo(() => {
    const result: Record<DocumentType, { file: FileManagerFileInterface; document: Document }[]> = {
      holdingSocialSecurityCard: [],
      socialSecurityCard: [],
      identificationDocument: [],
      passport: [],
      utilityBill: [],
    };

    documents.forEach((doc) => {
      if (!result[doc.documentType]) {
        result[doc.documentType] = [];
      }

      result[doc.documentType].push({
        file: {
          uid: String(doc.id),
          name: doc?.document?.name || doc?.name,
          fileId: doc?.document?.id,
          url: doc?.document?.url,
          missing: doc.missing,
        },
        document: doc,
      });
    });

    return result;
  }, [documents]);

  const removeUploadedDocument = (responseId: number | undefined) => {
    if (responseId === undefined) {
      return;
    }
    setDocuments((prev) => {
      const cloned = prev.slice();
      const index = cloned.findIndex((doc) => doc?.document?.id === responseId);
      if (index !== -1) {
        cloned.splice(index, 1);
      }

      return cloned;
    });
  };

  const handleEdit = async () => {
    if (Object.keys(uploadedFiles).length === 0) {
      setError("You need to upload at least one document.");
      return;
    } else {
      setError(undefined);
    }

    setIsLoading(true);

    const entries = Object.entries(uploadedFiles);
    for (let i = 0; i < entries.length; i++) {
      const [documentType, files] = entries[i];

      for (let j = 0; j < files.length; j++) {
        const file = files[j];

        const data = {
          document: file.file,
          documentType,
        };
        try {
          if (file.operation === "update") {
            await api.put(`${process.env.REACT_APP_API_URL}/api/documents/${file.id}`, {
              data,
            });
          } else {
            await api.post(`${process.env.REACT_APP_API_URL}/api/documents`, {
              data,
            });
          }
        } catch {}
      }
    }

    notificationApi.success({
      message: "Success",
      description: "Documents added.",
    });

    setUploadedFiles(initialState);
    Object.values(uploaderCleanFunctions.current).forEach((func) => {
      func();
    });
    updateDocuments();
    setIsLoading(false);
  };

  const onCleanFunction = (name: DocumentType) => {
    return (cleanFunction: () => void) => {
      uploaderCleanFunctions.current[name] = cleanFunction;
    };
  };

  return (
    <Spin spinning={isLoading}>
      {contextHolder}
      <h3 className="formTitle">Profile</h3>
      <div className={styles.profileContainer}>
        {isLoading && <span>Loading...</span>}
        <DocumentsManager
          title="Copy or picture from ID/Passport/Driver license"
          onCleanFunction={onCleanFunction("passport")}
          documentType="passport"
          onFilePayload={onFilePayload}
          onRemove={removeUploadedDocument}
          documentList={defaultFileList.passport}
        />
        <DocumentsManager
          title="Copy Picture of holding uploaded identification document"
          onCleanFunction={onCleanFunction("identificationDocument")}
          documentType="identificationDocument"
          onFilePayload={onFilePayload}
          onRemove={removeUploadedDocument}
          documentList={defaultFileList.identificationDocument}
        />
        <DocumentsManager
          title="Utility bill (no mobile bill, not older than 2 months)"
          onCleanFunction={onCleanFunction("utilityBill")}
          documentType="utilityBill"
          onFilePayload={onFilePayload}
          onRemove={removeUploadedDocument}
          documentList={defaultFileList.utilityBill}
        />
        <DocumentsManager
          title="Copy or picture of social security card"
          onCleanFunction={onCleanFunction("socialSecurityCard")}
          documentType="socialSecurityCard"
          onFilePayload={onFilePayload}
          onRemove={removeUploadedDocument}
          documentList={defaultFileList.socialSecurityCard}
        />
        <DocumentsManager
          title="Picture of holding the uploaded social security card"
          onCleanFunction={onCleanFunction("holdingSocialSecurityCard")}
          documentType="holdingSocialSecurityCard"
          onFilePayload={onFilePayload}
          onRemove={removeUploadedDocument}
          documentList={defaultFileList.holdingSocialSecurityCard}
        />
        {error && <Typography.Text type="danger">{error}</Typography.Text>}
        {Object.keys(uploadedFiles).length > 0 && <Button onClick={handleEdit}>Save</Button>}
      </div>
    </Spin>
  );
};
