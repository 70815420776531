import React, { ReactNode, useEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from "./input.module.css";

export interface InputProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  label?: string;
  type?: string;
  extra?: ReactNode;
  disabled?: boolean;
  required?: boolean;
  value?: string;
  className?: string;
  error?: boolean;
}

interface SimpleInputProps extends InputProps {
  multiline?: boolean;
  lines?: number;
}

export const Input = ({
  onChange,
  value: propsValue,
  required,
  disabled,
  placeholder,
  extra,
  label,
  type = "text",
  className,
  error = false,
  multiline = false,
  lines = 1,
}: SimpleInputProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState<string>(propsValue || "");

  useEffect(() => {
    setValue(propsValue || "");
  }, [propsValue]);

  return (
    <div className={cn(styles.container, disabled && styles.disabled, className)}>
      {multiline ? (
        <textarea
          rows={lines}
          ref={ref as any}
          required={required}
          className={cn(styles.input, styles.textarea, error && styles.inputErrorHighlight)}
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            onChange(e as any);
            setValue(e.target.value);
          }}
        />
      ) : (
        <input
          ref={ref}
          required={required}
          className={cn(styles.input, error && styles.inputErrorHighlight)}
          type={type}
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            onChange(e);
            setValue(e.target.value);
          }}
        />
      )}
      <span
        onClick={() => {
          ref.current?.focus();
        }}
        className={cn(styles.label, (value.length > 0 || multiline) && styles.activeLabel)}
      >
        {label}
      </span>
      {extra}
    </div>
  );
};
