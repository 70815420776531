import React from "react";
import cn from "classnames";
import { ReactComponent as CurveIcon } from "../../../assets/curve.svg";
import SectionBodyBg from "../../../assets/aboutUs/aboutUsSectionBodyBg.png";
import { ReactComponent as GameCube } from "../../../assets/gameCubeV4.svg";
import styles from "./aboutUsSection.module.css";

interface AboutUsSectionProps {
  title: string;
  description: string;
  imageSrc: string;
  mobileImageSrc: string;
  reverse?: boolean;
  bodyClassName?: string;
}

export const AboutUsSection = ({
  title,
  reverse = false,
  description,
  imageSrc,
  bodyClassName,
  mobileImageSrc,
}: AboutUsSectionProps) => {
  return (
    <div className={cn(styles.section, reverse && styles.reverseSection)}>
      <div className={styles.imageContainer}>
        <img className={styles.mobileImage} src={mobileImageSrc} alt={`${title} mobile image`} />
        <img className={styles.image} src={imageSrc} alt={`${title} image`} />
      </div>
      <div className={cn(styles.body, bodyClassName)}>
        <div className={styles.bodyImage}>
          <img src={SectionBodyBg} alt="about us section background image" />
          <GameCube className={styles.bodyImageCube} />
        </div>

        <div className={styles.titleContainer}>
          <div className={styles.aboutUsTitle}>About us</div>
          <CurveIcon className={styles.curve} />
          <h4 className={styles.title}>{title}</h4>
        </div>
        <p className={styles.description}>{description}</p>
      </div>
    </div>
  );
};
