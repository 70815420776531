import React, { FunctionComponent, SVGProps } from "react";
import cn from "classnames";
import { Link } from "../link";
import { ReactComponent as Facebook } from "../../assets/social/facebook.svg";
import { ReactComponent as Twitter } from "../../assets/social/twitter.svg";
import { ReactComponent as Instagram } from "../../assets/social/instagram.svg";
import { ReactComponent as Google } from "../../assets/social/google.svg";
import { ReactComponent as Telegram } from "../../assets/social/telegram.svg";
import styles from "./socialButton.module.css";

type SocialType = "facebook" | "twitter" | "instagram" | "google" | "telegram";

interface SocialButtonProps {
  type: SocialType;
  className?: string;
}

interface ComponentInterface {
  component: FunctionComponent<SVGProps<SVGSVGElement>>;
  to: string;
}

const components: Record<SocialType, ComponentInterface> = {
  facebook: { component: Facebook, to: "#" },
  twitter: { component: Twitter, to: "#" },
  instagram: { component: Instagram, to: "#" },
  google: { component: Google, to: "#" },
  telegram: { component: Telegram, to: "#" },
};
export const SocialButton = ({ type, className }: SocialButtonProps) => {
  const { component: Component, to } = components[type] || { component: null, to: "#" };

  return Component ? (
    <Link to={to}>
      <Component className={cn(className, styles.social)} />
    </Link>
  ) : null;
};
