import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Spin, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { api } from "../../../services/httpService";
import { DocumentsManager, FilePayloadItemInterface } from "../../profile/userProfile/documentsManager";
import { Document, DocumentType } from "../../../types/document";
import { FileManagerFileInterface } from "../../../components/fileManager";

const initialState = {};
export const UserDocuments = () => {
  const [uploadedFiles, setUploadedFiles] = useState<Record<string, FilePayloadItemInterface[]>>(initialState);
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [documents, setDocuments] = useState<Document[]>([]);
  const navigate = useNavigate();
  const uploaderCleanFunctions = useRef<Record<string, () => void>>({});

  useEffect(() => {
    if (initialState === uploadedFiles) {
      return;
    }

    if (Object.keys(uploadedFiles).length === 0) {
      setError("You need to upload at least one document.");
    } else {
      setError(undefined);
    }
  }, [uploadedFiles]);

  const updateDocuments = () => {
    api
      .get<Document[]>(`${process.env.REACT_APP_API_URL}/api/documents`)
      .then((res) => {
        const documents = res.data;

        setDocuments(documents);
      })
      .catch(() => {
        setDocuments([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    updateDocuments();
  }, []);
  const submit = async () => {
    if (Object.keys(uploadedFiles).length === 0) {
      if (documents.length > 0) {
        navigate("/sign-up/ssn-info");
      } else {
        setError("You need to upload at least one document.");
      }
      return;
    } else {
      setError(undefined);
    }

    setIsLoading(true);
    const entries = Object.entries(uploadedFiles);
    for (let i = 0; i < entries.length; i++) {
      const [documentType, files] = entries[i];

      for (let j = 0; j < files.length; j++) {
        const file = files[j];

        const data = {
          document: file.file,
          documentType,
        };
        try {
          if (file.operation === "update") {
            await api.put(`${process.env.REACT_APP_API_URL}/api/documents/${file.id}`, {
              data,
            });
          } else {
            await api.post(`${process.env.REACT_APP_API_URL}/api/documents`, {
              data,
            });
          }
        } catch {}
      }
    }
    setIsLoading(false);
    navigate("/sign-up/ssn-info");
  };

  const defaultFileList = useMemo(() => {
    const result: Record<DocumentType, { file: FileManagerFileInterface; document: Document }[]> = {
      holdingSocialSecurityCard: [],
      socialSecurityCard: [],
      identificationDocument: [],
      passport: [],
      utilityBill: [],
    };

    documents.forEach((doc) => {
      if (!result[doc.documentType]) {
        result[doc.documentType] = [];
      }

      result[doc.documentType].push({
        file: {
          uid: String(doc.id),
          name: doc?.document?.name || doc?.name,
          fileId: doc?.document?.id,
          url: doc?.document?.url,
          missing: doc.missing,
        },
        document: doc,
      });
    });

    return result;
  }, [documents]);

  const onFilePayload = (payload: any[], name: string) => {
    setUploadedFiles((prev) => {
      if (payload.length === 0) {
        const clone = { ...prev };
        delete clone[name];

        return clone;
      } else {
        return { ...prev, [name]: payload };
      }
    });
  };

  const removeUploadedDocument = (responseId: number | undefined) => {
    if (responseId === undefined) {
      return;
    }
    setDocuments((prev) => {
      const cloned = prev.slice();
      const index = cloned.findIndex((doc) => doc?.document?.id === responseId);
      if (index !== -1) {
        cloned.splice(index, 1);
      }

      return cloned;
    });
  };

  const onCleanFunction = (name: DocumentType) => {
    return (cleanFunction: () => void) => {
      uploaderCleanFunctions.current[name] = cleanFunction;
    };
  };

  return (
    <Spin spinning={isLoading}>
      <div className="formContainer">
        <h3 className="formTitle">Documents</h3>
        <div className="formInputs">
          <DocumentsManager
            title="Copy or picture from ID/Passport/Driver license"
            onCleanFunction={onCleanFunction("passport")}
            documentType="passport"
            onFilePayload={onFilePayload}
            onRemove={removeUploadedDocument}
            documentList={defaultFileList.passport}
          />
          <DocumentsManager
            title="Copy Picture of holding uploaded identification document"
            onCleanFunction={onCleanFunction("identificationDocument")}
            documentType="identificationDocument"
            onFilePayload={onFilePayload}
            onRemove={removeUploadedDocument}
            documentList={defaultFileList.identificationDocument}
          />
          <DocumentsManager
            title="Utility bill (no mobile bill, not older than 2 months)"
            onCleanFunction={onCleanFunction("utilityBill")}
            documentType="utilityBill"
            onFilePayload={onFilePayload}
            onRemove={removeUploadedDocument}
            documentList={defaultFileList.utilityBill}
          />
          <DocumentsManager
            title="Copy or picture of social security card"
            onCleanFunction={onCleanFunction("socialSecurityCard")}
            documentType="socialSecurityCard"
            onFilePayload={onFilePayload}
            onRemove={removeUploadedDocument}
            documentList={defaultFileList.socialSecurityCard}
          />
          <DocumentsManager
            title="Picture of holding the uploaded social security card"
            onCleanFunction={onCleanFunction("holdingSocialSecurityCard")}
            documentType="holdingSocialSecurityCard"
            onFilePayload={onFilePayload}
            onRemove={removeUploadedDocument}
            documentList={defaultFileList.holdingSocialSecurityCard}
          />
          {error && <Typography.Text type="danger">{error}</Typography.Text>}
          <div>
            <Button onClick={submit}>Next</Button>
          </div>
        </div>
      </div>
    </Spin>
  );
};
