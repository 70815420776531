import React, { useEffect, useMemo, useState } from "react";
import { Col, Tooltip } from "antd";
import cn from "classnames";
import { FileManager, FileManagerFileInterface } from "../../../../components/fileManager";
import { Uploader } from "../../../../components/uploader";
import { Document, DocumentType } from "../../../../types/document";
import styles from "../userProfile.module.css";

export interface FilePayloadItemInterface {
  operation?: "update" | "create";
  id?: number;
  file: any;
}
interface DocumentManagerProps {
  title: string;
  documentList: { file: FileManagerFileInterface; document: Document }[];
  onRemove: (fileId: number | undefined) => void;
  documentType: DocumentType;
  onFilePayload: (payload: FilePayloadItemInterface[], name: string) => void;
  onCleanFunction: (cleanFunction: () => void) => void;
}

const initialState: Record<number, any> = {};
export const DocumentsManager = ({
  title,
  documentList,
  onRemove,
  documentType,
  onCleanFunction,
  onFilePayload,
}: DocumentManagerProps) => {
  const [missingDocuments, setMissingDocuments] = useState<Record<number, any>>(initialState);
  const [files, setFiles] = useState<any[]>([]);

  useEffect(() => {
    if (missingDocuments !== initialState) {
      const newDocuments = files.map((file) => ({ file, operation: "create" })) as FilePayloadItemInterface[];
      const missing = Object.entries(missingDocuments).map(([id, file]) => ({
        file,
        operation: "update",
        id: Number(id),
      })) as FilePayloadItemInterface[];
      onFilePayload([...newDocuments, ...missing], documentType);
    }
  }, [missingDocuments]);

  const onMissingDocument = (documentId: number) => {
    return (file: any | undefined) => {
      setMissingDocuments((prev) => {
        const cloned = { ...prev };
        if (file) {
          cloned[documentId] = file;
        } else {
          delete cloned[documentId];
        }

        return Object.keys(cloned).length === 0 ? initialState : cloned;
      });
    };
  };

  const documentManagerOnFilePayload = (files: any[], name: string) => {
    setFiles(files);
    const newDocuments = files.map((file) => ({ file, operation: "create" })) as FilePayloadItemInterface[];
    const missing = Object.entries(missingDocuments).map(([id, file]) => ({
      file,
      operation: "update",
      id: Number(id),
    })) as FilePayloadItemInterface[];

    onFilePayload([...newDocuments, ...missing], name);
  };

  return (
    <div className={styles.documentManagerBlock}>
      <p>{title}</p>
      {documentList.map(({ file, document }, index) => {
        return (
          <FileManager
            key={index}
            file={file}
            onRemove={() => {
              onRemove(file?.fileId);
            }}
            prefixComponent={
              <Col className={styles.fileItemPrefix}>
                <DocumentStatus document={document} />
              </Col>
            }
            onMissingDocument={onMissingDocument(document.id)}
          />
        );
      })}
      <Uploader
        name={documentType}
        onFilePayload={documentManagerOnFilePayload}
        showUploadList={{ showDownloadIcon: true }}
        cleanFileList={onCleanFunction}
      />
    </div>
  );
};

const DocumentStatus = ({ document }: { document: Document }) => {
  const tooltipText = useMemo(() => {
    if (document.confirmed) {
      return "Document confirmed";
    } else if (document.underReview) {
      return "Document under review";
    } else if (document.missing) {
      return "Document missing";
    } else {
      return "No status";
    }
  }, [document]);

  const statusComponent = useMemo(() => {
    let className = styles.noStatus;
    if (document.confirmed) {
      className = styles.confirmed;
    } else if (document.underReview) {
      className = styles.underReview;
    } else if (document.missing) {
      className = styles.missing;
    }

    return <span className={cn(styles.statusDot, className)} />;
  }, [document]);

  return (
    <Tooltip placement="topLeft" title={tooltipText}>
      {statusComponent}
    </Tooltip>
  );
};
