import { MouseEventHandler, ReactNode } from "react";
import cn from "classnames";
import styles from "./button.module.css";

type ButtonType = "primary" | "secondary";

interface ButtonProps {
  type?: ButtonType;
  disabled?: boolean;
  loading?: boolean;
  children?: string | ReactNode;
  extraLeft?: ReactNode;
  extraRight?: ReactNode;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const buttonStyles: Record<ButtonType, string> = {
  primary: styles.primary,
  secondary: styles.secondary,
};

export const Button = ({ type = "primary", onClick, children, extraRight, extraLeft, className }: ButtonProps) => {
  return (
    <button onClick={onClick} className={cn(styles.button, buttonStyles[type], className)}>
      {extraLeft}
      {children}
      {extraRight}
    </button>
  );
};
