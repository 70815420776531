import React, { ReactNode } from "react";
import cn from "classnames";
import styles from "./section.module.css";

interface SectionProps {
  className?: string;
  children?: ReactNode | ReactNode[];
}

export const Section = ({ className, children }: SectionProps) => {
  return <section className={cn(styles.section, className)}>{children}</section>;
};
