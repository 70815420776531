import axios from "axios";
import { ACCESS_TOKEN } from "../constants/localStorageConstants";

const getUserAccessToken = () => {
  const token = localStorage.getItem(ACCESS_TOKEN);
  return token ? `Bearer ${token}` : token;
};

export const api = axios.create();
api.interceptors.request.use(async (config) => {
  const accessToken = getUserAccessToken();

  if (accessToken) {
    // @ts-ignore
    config.headers = {
      ...config.headers,
      Authorization: accessToken,
    };
  }
  return config;
});
