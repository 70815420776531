import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { User } from "../types/user";
import { api } from "../services/httpService";
import { ACCESS_TOKEN } from "../constants/localStorageConstants";
import { useNavigate } from "react-router-dom";

interface AuthContextInterface {
  user: User | null;
  isLoading: boolean;
  isFetched: boolean;
  logout: () => void;
  update: () => void;
}

interface ProtectedRouteProps {
  authenticated: boolean;
  children: ReactNode;
}

interface ProtectedRouteWithCustomLogicProps {
  check: (user: User | null) => boolean;
  children: ReactNode;
  redirect?: string;
}
export const AuthContext = createContext<AuthContextInterface>({
  user: null,
  isLoading: true,
  isFetched: false,
  logout: () => {},
  update: () => Promise<void>,
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [user, setUser] = useState<User | null>(null);
  const [isFetched, setIsFetched] = useState<boolean>(false);
  const loadUser = (callback?: () => void) => {
    api
      .get(`${process.env.REACT_APP_API_URL}/api/users/me`)
      .then((user) => {
        const data = user.data;
        setUser({
          ...data,
          birthDate: new Date(data.birthDate),
          createdAt: new Date(data.createdAt),
          updatedAt: new Date(data.updatedAt),
        } as User);
      })
      .catch(() => {
        setUser(null);
      })
      .finally(() => {
        setIsLoading(false);
        callback && callback();
      });
  };

  useEffect(() => {
    loadUser();
    setIsFetched(true);
  }, []);

  const logout = () => {
    localStorage.removeItem(ACCESS_TOKEN);
    window.location.href = "/login";
  };

  const update = () => {
    return new Promise<void>((resolve) => {
      loadUser(resolve);
    });
  };

  return <AuthContext.Provider value={{ isFetched, user, isLoading, logout, update }}>{children}</AuthContext.Provider>;
};

export const ProtectedRoute = ({ authenticated, children }: ProtectedRouteProps) => {
  const { user, isLoading } = useContext(AuthContext);
  const [showContent, setShowContent] = useState<boolean>(authenticated ? !isLoading && user !== null : true);
  const navigate = useNavigate();

  useEffect(() => {
    if (authenticated && !isLoading) {
      const result = user !== null;
      setShowContent(result);

      if (!result) {
        navigate("/login", { replace: true });
      }
    }
  }, [isLoading, user]);

  return <>{!showContent && isLoading ? <span>Loading...</span> : showContent && children}</>;
};

export const ProtectedRouteWithCustomLogic = ({
  check,
  children,
  redirect = "/",
}: ProtectedRouteWithCustomLogicProps) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!check(user)) {
      navigate(redirect);
    }
  }, []);

  return <>{check(user) && children}</>;
};
