import React from "react";
import { Button } from "../../components/button";
import { WhoWeAreBlock } from "../../components/whoWeAreBlock";
import { OurService } from "./ourService";
import { Transform } from "./transform";
import { WhyChooseUs } from "./whyChooseUs";
import { ReactComponent as RightArrow } from "../../assets/rightArrow.svg";
import { ReactComponent as BannerIcon } from "../../assets/curve.svg";
import styles from "./home.module.css";

export const Home = () => {
  return (
    <div>
      <div className={styles.welcomeBanner}>
        <div className={styles.heading}>
          <p className={styles.welcomeText}>Welcome to Usoptool</p>
          <BannerIcon />
          <div className={styles.headingTitle}>Unlock Casino Marketing Insights</div>
          <span className={styles.subHeadingTitle}>Maximize Your Casino's Potential with Data-Driven Strategies</span>
          <Button type="secondary" className={styles.getStarted} extraRight={<RightArrow />}>
            Get started
          </Button>
        </div>
      </div>
      <WhoWeAreBlock />
      <WhyChooseUs />
      <OurService />
      <div className={styles.clientsStatistic}>
        <div className={styles.clientsStatisticItem}>
          <span className={styles.statisticItemLabel}>Casinos Empowered</span>
          <span className={styles.statisticItemValue}>500+</span>
        </div>
        <div className={styles.clientsStatisticBorderLine} />
        <div className={styles.clientsStatisticItem}>
          <span className={styles.statisticItemLabel}>Satisfied Clients</span>
          <span className={styles.statisticItemValue}>10k+</span>
        </div>
        <div className={styles.clientsStatisticBorderLine} />
        <div className={styles.clientsStatisticItem}>
          <span className={styles.statisticItemLabel}>Marketing ROI Increase</span>
          <span className={styles.statisticItemValue}>95%</span>
        </div>
        <div className={styles.clientsStatisticBorderLine} />
        <div className={styles.clientsStatisticItem}>
          <span className={styles.statisticItemLabel}>Industry Awards</span>
          <span className={styles.statisticItemValue}>25+</span>
        </div>
      </div>
      <Transform />
    </div>
  );
};
