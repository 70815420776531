import React, { useEffect, useRef, useState } from "react";
import { DatePicker, Modal, notification, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Maybe } from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";
import { Input } from "../../../components/form/input";
import { PasswordInput } from "../../../components/form/input/passwordInput";
import { Button } from "../../../components/button";
import { api } from "../../../services/httpService";
import { ACCESS_TOKEN } from "../../../constants/localStorageConstants";
import { InviteCodeCheckModal, InviteModalRefInterface } from "../inviteCodeCheckModal";
import { checkEmail, checkPhoneNumber } from "../../../helpers/formHelpers";
import { getJoinUsRegisterBody } from "../../../helpers/registerHelpers";
import { Link } from "../../../components/link";
import styles from "../signUp.module.css";

interface PersonalDataBody {
  firstName: string;
  surName: string;
  middleName?: Maybe<string | undefined>;
  birthDate: Date;
  email: string;
  city: string;
  country: string;
  postCode: string;
  phoneNumber: string;
  ssnLastFourDigits: string;
}

const schema = yup
  .object({
    firstName: yup.string().required("First name required"),
    surName: yup.string().required("Surname required"),
    middleName: yup.string().notRequired(),
    birthDate: yup.date().required("Date of birth required"),
    email: yup
      .string()
      .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, "The email is not valid.")
      .required(),
    city: yup.string().required(),
    country: yup.string().required(),
    postCode: yup
      .string()
      .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, "Wrong post code")
      .required(),
    phoneNumber: yup
      .string()
      .matches(/^[0-9]{3}[-\s]?[0-9]{3}[-\s]?[0-9]{4}$/, "Wrong phone number")
      .required(),
    ssnLastFourDigits: yup
      .string()
      .matches(/^\d{4}$/, "Wrong SSN last 4 digits")
      .required(),
    password: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Password should be more than 8 characters, with at least 1 uppercase (A-Z) or lowercase letter (a-z), at least 1 numeric character (0-9), and at least 1 special character."
      )
      .required(),
  })
  .required();

export const UserPersonalData = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const joinUsBody = getJoinUsRegisterBody() || {};
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      city: "",
      country: "",
      postCode: "",
      ssnLastFourDigits: "",
      password: "",
      ...joinUsBody,
    },
  });
  const [authSuccess, setAuthSuccess] = useState<boolean>(false);
  const inviteCodeCheckRef = useRef<InviteModalRefInterface>(null);
  const [notificationApi, contextHolder] = notification.useNotification();
  const navigate = useNavigate();

  useEffect(() => {
    if (!joinUsBody) {
      navigate("/join-us");
    }
  }, [joinUsBody]);

  useEffect(() => {
    const joinUsFormErrors = [!!errors.email, !!errors.firstName, !!errors.surName, !!errors.phoneNumber];

    if (joinUsFormErrors.filter((error) => error).length > 0) {
      notificationApi.error({
        message: "Error",
        description: (
          <p>
            Something went wrong with your registration body, please fill{" "}
            <Link className={styles.link} to="/join-us">
              Join Us
            </Link>{" "}
            form again.
          </p>
        ),
      });
    }
  }, [errors]);

  const duplicatesCheck = async (data: PersonalDataBody) => {
    const emailCheck = await checkEmail(data.email);
    const phoneCheck = await checkPhoneNumber(data.phoneNumber);
    if (!emailCheck) {
      setError("email", { type: "custom", message: "Current email already registered" });
      return;
    } else {
      clearErrors("email");
    }
    if (!phoneCheck) {
      setError("phoneNumber", { type: "custom", message: "Current phone number already registered" });
      return;
    } else {
      clearErrors("phoneNumber");
    }

    return true;
  };

  const onSubmit = async (data: PersonalDataBody) => {
    localStorage.removeItem(ACCESS_TOKEN);
    const check = await duplicatesCheck(data);
    if (!check) {
      return;
    }
    if (!inviteCodeCheckRef.current) {
      return;
    }

    const code = await inviteCodeCheckRef.current.openCheckInviteModal();
    if (!code) {
      return;
    }

    setIsLoading(true);
    api
      .post(`${process.env.REACT_APP_API_URL}/api/auth/local/register`, {
        ...data,
        phoneCountryCode: "+380",
        username: data.email,
        inviteCode: code,
      })
      .then((result) => {
        if (result.status === 200) {
          setAuthSuccess(true);
        }
      })
      .catch(() => {
        notificationApi.error({
          message: "Error",
          description: "Something went wrong. Try again or reload page and try again.",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="formContainer">
      {contextHolder}
      <InviteCodeCheckModal ref={inviteCodeCheckRef} />
      <Modal title="Information" open={authSuccess} footer={null} closeIcon={<span />}>
        <p>We have sent you a letter to your email with a confirmation link.</p>
      </Modal>
      <h3 className="formTitle">Personal Data</h3>
      <div className="formInputs">
        {/*
        <div>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { ...props } }) => <Input label="First Name" disabled={isLoading} {...props} />}
            name="firstName"
          />
          {errors.firstName && <Typography.Text type="danger">{errors.firstName.message}</Typography.Text>}
        </div>
        <div>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { ...props } }) => <Input label="Surname" disabled={isLoading} {...props} />}
            name="surName"
          />
          {errors.surName && <Typography.Text type="danger">{errors.surName.message}</Typography.Text>}
        </div>
         */}
        <div>
          <Controller
            control={control}
            render={({ field: { ...props } }) => (
              <Input
                label="Middle name"
                disabled={isLoading}
                {...props}
                value={props.value ? String(props.value) : undefined}
              />
            )}
            name="middleName"
          />
          {errors.middleName && <Typography.Text type="danger">{errors.middleName.message}</Typography.Text>}
        </div>
        {/*
        <div>
          <Controller
            rules={{
              required: true,
            }}
            control={control}
            render={({ field: { ...props } }) => <Input label="Email" type="emai" disabled={isLoading} {...props} />}
            name="email"
          />
          {errors.email && <Typography.Text type="danger">{errors.email.message}</Typography.Text>}
        </div>
         */}
        <div>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { ...props } }) => <PasswordInput label="Password" {...props} />}
            name="password"
          />
          {errors.password && <Typography.Text type="danger">{errors.password.message}</Typography.Text>}
        </div>
        <div>
          <div>
            <Controller
              rules={{
                required: true,
              }}
              control={control}
              render={({ field: { ...props } }) => (
                <DatePicker
                  placeholder="Date of birth"
                  disabled={isLoading}
                  onChange={(date) => {
                    if (date) {
                      const value = date.toDate();
                      props.onChange({ target: { value } });
                    }
                  }}
                />
              )}
              name="birthDate"
            />
          </div>

          {errors.birthDate && <Typography.Text type="danger">{errors.birthDate.message}</Typography.Text>}
        </div>
        <div>
          <Controller
            rules={{
              required: true,
            }}
            control={control}
            render={({ field: { ...props } }) => <Input label="City" disabled={isLoading} {...props} />}
            name="city"
          />
          {errors.city && <Typography.Text type="danger">{errors.city.message}</Typography.Text>}
        </div>
        <div>
          <Controller
            rules={{
              required: true,
            }}
            control={control}
            render={({ field: { ...props } }) => <Input label="Country" disabled={isLoading} {...props} />}
            name="country"
          />
          {errors.country && <Typography.Text type="danger">{errors.country.message}</Typography.Text>}
        </div>
        <div>
          <Controller
            rules={{
              required: true,
            }}
            control={control}
            render={({ field: { ...props } }) => <Input label="Post code" disabled={isLoading} {...props} />}
            name="postCode"
          />
          {errors.postCode && <Typography.Text type="danger">{errors.postCode.message}</Typography.Text>}
        </div>
        {/*
        <div>
          <Controller
            rules={{
              required: true,
            }}
            control={control}
            render={({ field: { ...props } }) => (
              <Input label="Phone number" type="phone" disabled={isLoading} {...props} />
            )}
            name="phoneNumber"
          />
          {errors.phoneNumber && <Typography.Text type="danger">{errors.phoneNumber.message}</Typography.Text>}
        </div>
         */}
        <div>
          <Controller
            rules={{
              required: true,
            }}
            control={control}
            render={({ field: { ...props } }) => (
              <Input label="Last 4 digits of social security number (ssn)" disabled={isLoading} {...props} />
            )}
            name="ssnLastFourDigits"
          />
          {errors.ssnLastFourDigits && (
            <Typography.Text type="danger">{errors.ssnLastFourDigits.message}</Typography.Text>
          )}
        </div>
        <Button type="primary" loading={isLoading} onClick={handleSubmit(onSubmit)}>
          {isLoading ? <LoadingOutlined /> : "Continue"}
        </Button>
      </div>
    </div>
  );
};
