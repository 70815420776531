import React, { useContext, useEffect, useMemo, useState } from "react";
import { Col, Dropdown, Result, Row } from "antd";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import DownOutlined from "@ant-design/icons/DownOutlined";
import RightOutlined from "@ant-design/icons/RightOutlined";
import UserOutlined from "@ant-design/icons/UserOutlined";
import { Overlay } from "../overlay";
import { Button } from "../button";
import { HomeTop } from "./homeTop";
import { Footer } from "./footer";
import { MobileMenuDrawer } from "./mobileMenuDrawer";
import { Link } from "../link";
import { ReactComponent as HomeLogo } from "../../assets/home/homeLogo.svg";
import { ReactComponent as MenuIcon } from "../../assets/menuIcon.svg";
import { ReactComponent as UserDataCard } from "../../assets/userDataCardRegular.svg";
import { AuthContext } from "../../providers/authProvider";
import styles from "./layout.module.css";

export const Layout = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [menuDrawerOpen, setMenuDrawerOpen] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const openHomePage = () => {
    navigate("/");
  };

  const openMenuDrawer = () => {
    setMenuDrawerOpen(true);
  };

  const onCloseDrawer = () => {
    setMenuDrawerOpen(false);
  };

  const completeRegistrationComponent = useMemo(() => {
    if (!user || ["/login", "/sign-up", "/forgot-password", "/join-us", "/reset-password"].includes(pathname)) {
      return;
    }
    if (!user.documentsRegistered) {
      if (pathname === "/sign-up/documents") {
        return;
      }
      return (
        <div>
          <p>
            Fill the{" "}
            <Link className={styles.link} to="/sign-up/documents">
              Documents
            </Link>
          </p>
        </div>
      );
    } else if (!user.ssnInfo) {
      if (pathname === "/sign-up/ssn-info") {
        return;
      }

      return (
        <div>
          <p>
            Fill the{" "}
            <Link className={styles.link} to="/sign-up/ssn-info">
              SSN info
            </Link>
          </p>
        </div>
      );
    }
  }, [user, pathname]);

  return (
    <>
      {completeRegistrationComponent && (
        <Overlay>
          <Result
            className={styles.completeRegistrationResult}
            icon={<UserDataCard className={styles.completeRegistrationIcon} />}
            title="Please complete your registration."
            extra={completeRegistrationComponent}
          />
        </Overlay>
      )}
      <MobileMenuDrawer open={menuDrawerOpen} onClose={onCloseDrawer} />
      <div className={styles.wrapper}>
        <HomeTop />
        <Row justify="space-between" wrap={false} align="middle" className={styles.header}>
          <Col>
            <div onClick={openHomePage} className={styles.logoContainer}>
              <HomeLogo className={styles.logoIcon} />
            </div>
          </Col>
          <Col>
            <Row align="middle" wrap={false} className={styles.headerEndContent}>
              <Row align="middle" wrap={false} className={styles.headerMenu}>
                <Link to="/" className={styles.menuItem}>
                  Home
                </Link>
                <Link to="/about-us" className={styles.menuItem}>
                  About Us
                </Link>
                <Dropdown
                  menu={{
                    items: [
                      {
                        label: "Item 1",
                        key: "0",
                      },
                      {
                        label: "Item 2",
                        key: "1",
                      },
                    ],
                  }}
                  trigger={["click"]}
                >
                  <a className={styles.menuItem} onClick={(e) => e.preventDefault()}>
                    Services
                    <DownOutlined className={styles.servicesIcon} />
                  </a>
                </Dropdown>
                <Link to="/what-we-do" className={styles.menuItem}>
                  What we do
                </Link>
                <Link to="/contact-us" className={styles.menuItem}>
                  Contact Us
                </Link>
              </Row>
              <button className={styles.mobileMenu} onClick={openMenuDrawer}>
                <MenuIcon className={styles.mobileMenuIcon} />
              </button>
              {user ? (
                <Link to="/profile" className={styles.menuItem}>
                  <Button className={styles.headerEndContentBtn} type="primary">
                    <div className={styles.headerEndContentBtnInner}>
                      <span>Profile</span>
                      <UserOutlined />
                    </div>
                  </Button>
                </Link>
              ) : (
                <Link to="/login" className={styles.menuItem}>
                  <Button className={styles.headerEndContentBtn} type="primary">
                    <div className={styles.headerEndContentBtnInner}>
                      <span>Sign in</span>
                      <RightOutlined className={styles.headerEndContentBtnIcon} />
                    </div>
                  </Button>
                </Link>
              )}
            </Row>
          </Col>
        </Row>
        <div className={styles.body}>
          <Outlet />
        </div>
        <Footer />
      </div>
    </>
  );
};
